import type { BigNumberish } from '@ethersproject/bignumber';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';
import numbro from 'numbro';
import { isString } from 'util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultDecimals = (value: any) => {
  const number = Number(value);
  if (Number.isNaN(number)) return 4;
  if (number === 0) return 2;

  if (number < 1 && number > -1) return 4;
  else return 2;
};

export const formatNumber = (
  n: number,
  displayDecimals = defaultDecimals(n),
  trimMantissa?: boolean,
  average?: boolean,
) =>
  numbro(n).format({
    thousandSeparated: true,
    mantissa: displayDecimals,
    trimMantissa: trimMantissa ?? false,
    average: average ?? false,
  });

const addThousandSeparator = (
  n: string | number | BigNumber,
  separator = ',',
): string => {
  const parts = n.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);

  return parts.join('.');
};

export const limitDecimals = (
  value: string | number | BigNumber,
  maxDecimals?: number,
): string => {
  let valueStr = value.toString();

  if (!value) return valueStr;
  if (maxDecimals === undefined) return valueStr;
  if (maxDecimals === 0) return valueStr.split('.')[0];

  const dotIndex = valueStr.indexOf('.');

  if (dotIndex === -1) return valueStr;

  const decimals = valueStr.length - dotIndex - 1;

  if (decimals > maxDecimals) {
    valueStr = valueStr.substring(
      0,
      valueStr.length - (decimals - maxDecimals),
    );
  }

  return valueStr;
};
type KmbThreshold = 1e3 | 1e6 | 1e9;

type KmbFormatThreshold = KmbThreshold | [KmbThreshold, KmbThreshold];

const toPrecision = (n: number, displayDecimals: number): string => {
  const precision = Math.pow(10, displayDecimals);

  return (Math.floor(n * precision) / precision).toFixed(displayDecimals); // adding toFixed keep it as ${displayDecimals} when the ending is 0
};

const kmbFormatter = (
  n: number,
  threshold: KmbFormatThreshold,
  displayDecimals: number,
): string => {
  const isArray = Array.isArray(threshold);

  const lowerThreshold = isArray ? threshold[0] : threshold;
  const upperThreshold = isArray ? threshold[1] : Number.POSITIVE_INFINITY;

  const formatTo = (divider: number) =>
    addThousandSeparator(toPrecision(n / divider, displayDecimals));

  if (n < lowerThreshold) return `${addThousandSeparator(n)}`;
  if (n >= 1e9 && upperThreshold > 1e9) return `${formatTo(1e9)}B`;
  if (n >= 1e6 && upperThreshold > 1e6) return `${formatTo(1e6)}M`;
  if (n >= 1e3 && upperThreshold > 1e3) return `${formatTo(1e3)}K`;
  return `${addThousandSeparator(n)}`;
};

export interface FormatAmountOptions {
  decimals?: number;
  // @displayDecimals
  // how many decimals should be displayed, default to 2
  // examples:
  //   100.1234 => 100.12
  //   100.12999 => 100.12
  displayDecimals?: number;
  // @defaultValue
  // the fallback value to be used when the input amount is undefined
  // or the input amount is not valid number
  defaultValue?: string;
  // @trimTrailingZeros
  // whether to remove the decimal trailing zero
  // examples:
  //   100.10 => 100.1;
  //   100.10100 => 100.101;
  trimTrailingZeros?: boolean;
  // @thousandSeparator
  // whether to display the separator
  // thousandSeparator is enabled by default
  // pass false to disable thousandSeparator
  // examples:
  //   1000.1234 => 1,000.1234
  //   1000000.1234 => 1,000,000.1234
  thousandSeparator?: string | false;
  // @kmbFormatThreshold
  // whether to enable the K, M, B formatter
  // examples:
  //   1000 => 1k
  //   10000 => 10k
  //   1000000 => 1M
  //   1000000000 => 1B
  kmbFormatThreshold?: KmbFormatThreshold;
}

const padDecimals = (value: string, decimalsLen: number): string => {
  const valueStr = value;

  const dotIndex = valueStr.indexOf('.');

  if (dotIndex === -1) return `${valueStr}.`.padEnd(decimalsLen, '0');

  const decimals = valueStr.length - dotIndex - 1;

  if (decimals < decimalsLen)
    return valueStr.padEnd(valueStr.length + (decimalsLen - decimals), '0');

  return valueStr;
};

export const formatAmount = (
  value: string | number | BigNumber | undefined,
  options?: FormatAmountOptions,
): string => {
  const {
    decimals = 18,
    displayDecimals = 2,
    defaultValue = '-',
    trimTrailingZeros = false,
    kmbFormatThreshold,
    thousandSeparator = ',',
  } = options ?? {};

  if (value === undefined || value.toString().length === 0) {
    return defaultValue;
  }

  let valueStr = value.toString();

  if (decimals !== 0) valueStr = formatUnits(value, decimals);

  valueStr = limitDecimals(valueStr, displayDecimals);

  if (trimTrailingZeros || displayDecimals === 0) {
    // trim tailing zeros
    valueStr = `${Number(valueStr)}`;
  } else {
    // pad tailing zeros;
    valueStr = padDecimals(valueStr, displayDecimals);
  }

  if (kmbFormatThreshold)
    return kmbFormatter(Number(valueStr), kmbFormatThreshold, displayDecimals);

  if (thousandSeparator) return addThousandSeparator(valueStr);

  return valueStr;
};

const convertScientificToDecimalString = (scientificNotation: string) => {
  const [base, exp] = scientificNotation.split('e').map(Number);
  let decimalString = base.toString();

  if (exp < 0) {
    // For negative exponents, prepend zeros
    const zeroesToAdd = Math.abs(exp) - 1;
    decimalString =
      '0.' + '0'.repeat(zeroesToAdd) + decimalString.replace('.', '');
  } else {
    // Handle positive exponents if necessary
    const [integerPart, fractionalPart = ''] = decimalString.split('.');
    const zeroesToAdd = exp - fractionalPart.length;
    if (zeroesToAdd > 0) {
      decimalString = integerPart + fractionalPart + '0'.repeat(zeroesToAdd);
    } else {
      decimalString =
        integerPart +
        fractionalPart.slice(0, exp) +
        '.' +
        fractionalPart.slice(exp);
    }
  }

  return decimalString;
};

export const safeStringify = (
  value: number | string | undefined,
  defaultValue = '0',
) => {
  if (value === undefined) return defaultValue;
  if (typeof value === 'number' && isNaN(value)) return defaultValue;
  return value.toString();
};

export const safeParseUnits = (
  value: string | number | undefined,
  unitName?: BigNumberish,
): BigNumber => {
  try {
    let newNumber = value;
    if (isString(value) && value.includes('e')) {
      newNumber = convertScientificToDecimalString(value);
    }
    const newValue = safeStringify(newNumber);
    return parseUnits(newValue, unitName);
  } catch (e) {
    return BigNumber.from(0);
  }
};

export const parseStringValueToBn = (
  value: string,
  decimals: number,
  defaultValue = BigNumber.from(0),
): BigNumber => {
  const pValue = Number.parseFloat(value);

  if (Number.isNaN(pValue)) return defaultValue;

  value = limitDecimals(value, decimals);
  const amount = parseUnits(value, decimals);

  return BigNumber.from(amount);
};

export function expandDecimals(decimals: number): BigNumber;
export function expandDecimals(n: number, decimals?: number): BigNumber {
  if (decimals === undefined) {
    decimals = Number(n);
    n = 1;
  }

  return BigNumber.from(n).mul(BigNumber.from(10).pow(decimals));
}

export function convertBnToNumber(
  value: BigNumberish,
  decimals = 18,
  formatDecimals = 2,
): number {
  try {
    return Number(limitDecimals(formatUnits(value, decimals), formatDecimals));
  } catch (error) {
    return 0;
  }
}
