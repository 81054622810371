import { Grid, GridItem, Image, VStack } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import { StakeInfoToken } from '@ui/components/Atoms/StakaInfoToken';
import { VaultInfoChip } from '@ui/components/Atoms/VaultInfoChip';
import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import { formatNumber } from '@ui/utils/formatNumber';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';

interface CurrentStakeInfoDetailProps {
  currentVault: VaultCardInfo;
  totalStaked: BigNumber;
}

const CurrentStakeInfoDetail: FC<CurrentStakeInfoDetailProps> = ({
  currentVault,
  totalStaked,
}) => {
  const t = useTranslations();
  return (
    <Grid templateColumns="repeat(2, 1fr)" w="100%" rowGap={4}>
      <GridItem>
        <Image
          src="/texts/currentVaultBenefit.svg"
          width="100px"
          alt="currentVaultBenefit"
        />
      </GridItem>
      <GridItem>
        <VStack gap={2} alignItems="start">
          <VaultInfoChip>
            {formatNumber((currentVault.apr ?? 0) * 100)} % APR
          </VaultInfoChip>
          <VaultInfoChip>
            {t('common.{rate} Multiplier', {
              rate: `${currentVault.multiplier}x`,
            })}
          </VaultInfoChip>
        </VStack>
      </GridItem>
      <GridItem>
        <Image
          src="/texts/totalAmountStaked.svg"
          width="100px"
          alt="totalAmountStaked"
        />
      </GridItem>
      <GridItem margin="auto 0">
        <StakeInfoToken
          value={totalStaked}
          amountProps={{ color: 'white' }}
          iconSize={8}
          textProps={{ fontSize: '24px', fontWeight: 700 }}
        />
      </GridItem>
    </Grid>
  );
};

export default CurrentStakeInfoDetail;
