import { ChakraProvider } from '@chakra-ui/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BugsnagErrorBoundary, bugsnagNotify } from '@ui/components/bugsnag';
import { GlobalComponents } from '@ui/components/GlobalComponents';
import { Scripts } from '@ui/components/Scripts';
import { useInitializeApp } from '@ui/hooks/useInitializeApp';
import { IntlProviderWithPageProps } from '@ui/locales/IntlProviderWithPageProps';
import chakraSystem from '@ui/theme';
import { Fonts } from '@ui/theme/Fonts';
import type { NextComponentType } from 'next';
import type { AppProps } from 'next/app';

interface NextAppProps extends AppProps {
  Component: NextComponentType;
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, q) => {
      console.error('error at query', q.queryKey);
      console.error(error);
      bugsnagNotify(
        new Error(`error at query: [${q.queryKey.toString()}]`, {
          cause: error,
        }),
      );
    },
  }),
});

function MyApp({ Component, pageProps }: NextAppProps) {
  const { ...restProps } = pageProps;
  useInitializeApp();
  return (
    <>
      <Fonts />
      <Scripts />
      <QueryClientProvider client={queryClient}>
        <ChakraProvider value={chakraSystem}>
          <BugsnagErrorBoundary>
            <IntlProviderWithPageProps pageProps={restProps}>
              <GlobalComponents />
              <Component {...restProps} />
            </IntlProviderWithPageProps>
          </BugsnagErrorBoundary>
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
