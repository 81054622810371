import { useIsMounted } from '@ui/hooks/useIsMounted';

export interface NoSSRProps {
  children: React.ReactNode;
  ssrPlaceholder?: React.ReactNode;
}

export const NoSSR: React.FC<NoSSRProps> = ({ children, ssrPlaceholder }) => {
  const isMounted = useIsMounted();

  if (isMounted) return <>{children}</>;

  return <>{ssrPlaceholder}</>;
};
