import { currentWallet, useDesiredChain } from '@cronos-app/wallet';
import type { BigNumber } from '@ethersproject/bignumber';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toaster } from '@ui/components/Atoms/Toast';
import { getSDK } from '@ui/utils';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { getUseBaraBalanceQueryKey } from '../useBaraBalance';
import { getUseBaraVaultUserInfoQueryKey } from './useBaraVaultUserInfo';

const useBaraWithdraw = () => {
  const t = useTranslations();

  const desiredChainId = useDesiredChain();
  const sdk = getSDK(desiredChainId);
  const [isLoading, setIsLoading] = useState(false);
  const account = currentWallet.useAccount();
  const provider = currentWallet.useProvider();
  const isWrongNetwork = currentWallet.useIsWrongNetwork();
  const queryClient = useQueryClient();

  const data = useMutation(
    ['BaraVault.Withdraw'],
    async (stakeId: BigNumber) => {
      const signer = await provider?.getSigner();
      const baraVaultContract = sdk.getBaraVault(signer);

      setIsLoading(true);
      try {
        if (account && !isWrongNetwork) {
          const ret = await baraVaultContract.withdraw(stakeId.toString());

          toaster.create({
            title: t('common.Withdraw_Toast_Title'),
            type: 'loading',
            id: ret.hash,
            meta: { txHash: ret.hash },
          });

          await ret.wait();

          toaster.dismiss(ret.hash);

          toaster.success({
            title: t('common.Withdraw_Success_Toast_Title'),
            description: t('common.Withdraw_Success_Toast_Message'),
          });
        }
      } catch (e) {
        toaster.error({
          title: t('common.Withdraw_Error_Toast_Title'),
          description: t('common.Withdraw_Error_Toast_Message'),
        });
        setIsLoading(false);
      }
    },
    {
      onError: () => {
        setIsLoading(false);
      },
      onSuccess: () => {
        setIsLoading(false);
        queryClient.invalidateQueries(getUseBaraVaultUserInfoQueryKey(account));
        queryClient.invalidateQueries(getUseBaraBalanceQueryKey(account));
      },
    },
  );

  return {
    ...data,
    isLoading,
  };
};

export default useBaraWithdraw;
