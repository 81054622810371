import { HStack, Icon, VStack } from '@chakra-ui/react';
import { StakeInfoToken } from '@ui/components/Atoms/StakaInfoToken';
import type { Stake } from '@ui/interfaces/stake.interface';
import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import { useMemo } from 'react';
import { HiArrowRight } from 'react-icons/hi';

import { LockTimeChange } from './LockTimeChange';

interface UpgradeStakeDetailsProps {
  stake: Stake;
  vault: VaultCardInfo;
}

export const UpgradeStakeDetails = ({
  stake,
  vault,
}: UpgradeStakeDetailsProps) => {
  const newUnlockTimestamp = useMemo(
    () => stake.stakeTimestamp.add(vault.lockPeriod ?? 0),
    [stake, vault],
  );

  return (
    <VStack
      alignItems="flex-start"
      position="relative"
      px={3}
      gap={0}
      borderLeft="2px solid"
      borderColor="cPurple.300"
    >
      <StakeInfoToken
        value={stake.amount}
        iconSize={{ base: 5, lg: 7 }}
        amountProps={{
          color: 'unset',
          fontSize: { base: 20, lg: 22 },
        }}
        amountUsdProps={{
          color: 'unset',
          fontSize: { base: 16, lg: 18 },
        }}
      />

      <HStack align="center" py={{ base: 1, lg: 2 }}>
        <LockTimeChange
          textProps={{ fontSize: { base: 14, lg: 16 } }}
          unlockTimestamp={stake.unlockTimestamp.toNumber()}
        />
        <Icon boxSize={{ base: 4, lg: 6 }}>
          <HiArrowRight />
        </Icon>
        <LockTimeChange
          textProps={{ fontSize: { base: 18, lg: 20 } }}
          unlockTimestamp={newUnlockTimestamp.toNumber()}
        />
      </HStack>
    </VStack>
  );
};
