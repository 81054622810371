import type {
  IconProps,
  SkeletonProps,
  StackProps,
  TextProps,
} from '@chakra-ui/react';
import { Box, HStack, Icon, Skeleton, Text, VStack } from '@chakra-ui/react';
import { CapybaraToken } from '@cronos-app/icons';
import type { BigNumber } from '@ethersproject/bignumber';
import BaraAmountUsd from '@ui/components/Molecules/BaraAmountUsd';
import { isNil } from 'lodash-es';

import type { AmountProps } from '../Amount';
import { Amount } from '../Amount';

export type StakeInfoTokenProps = {
  text?: string;
  type?: string;
  value?: BigNumber;
  placeholder?: string;
  textProps?: TextProps;
  stackProps?: StackProps;
  amountProps?: Omit<AmountProps, 'value'>;
  amountUsdProps?: Omit<AmountProps, 'value'>;
  hideWhenZero?: boolean;
  hideWhenIsNil?: boolean;
  isHideUsd?: boolean;
  iconSize?: IconProps['boxSize'];
} & Omit<SkeletonProps, 'placeholder'>;

export const StakeInfoToken = ({
  value,
  text,
  placeholder,
  textProps,
  stackProps,
  amountProps,
  amountUsdProps,
  hideWhenZero,
  hideWhenIsNil,
  isHideUsd = false,
  iconSize = 5,
  ...skeletonProps
}: StakeInfoTokenProps) => {
  const isZero = Number(value) === 0;
  if (isZero && hideWhenZero) return null;
  if (hideWhenIsNil && isNil(value) && !text) return null;

  return (
    <Skeleton
      loading={isNil(value)}
      display="flex"
      alignItems="center"
      gap={1}
      textStyle={{ base: 'h6', lg: 'h5' }}
      {...skeletonProps}
    >
      {isZero && !!placeholder ? (
        <Text color="white" {...textProps}>
          {placeholder}
        </Text>
      ) : (
        <VStack alignItems="flex-start" gap={0} {...stackProps}>
          <HStack>
            <Icon boxSize={iconSize}>
              <CapybaraToken />
            </Icon>
            {value && (
              <Box
                fontSize="20px"
                fontWeight={700}
                lineHeight="22px"
                {...textProps}
              >
                <Amount
                  value={value}
                  decimals={18}
                  color="white"
                  {...amountProps}
                />
              </Box>
            )}
            {text && (
              <Text fontSize="20px" fontWeight={700} {...textProps}>
                {text}
              </Text>
            )}
          </HStack>
          {value && !isHideUsd && (
            <BaraAmountUsd value={value} {...amountUsdProps} />
          )}
        </VStack>
      )}
    </Skeleton>
  );
};
