import { create } from 'zustand';

type AccountModalView = 'default' | 'disclaimer';

interface AccountModalStore {
  isOpen: boolean;
  view: AccountModalView;
  disclaimerUrl: string;
  openModal: () => void;
  closeModal: () => void;
  openDisclaimer: (disclaimerUrl: string) => void;
  openDefault: () => void;
}

export const useAccountModal = create<AccountModalStore>((set) => ({
  isOpen: false,
  view: 'default',
  disclaimerUrl: '',
  closeModal: () => {
    // reset all data
    set({
      isOpen: false,
      view: 'default',
      disclaimerUrl: '',
    });
  },
  openModal: () => {
    set({
      isOpen: true,
    });
  },
  openDisclaimer: (disclaimerUrl) => {
    set({
      view: 'disclaimer',
      disclaimerUrl,
    });
  },
  openDefault: () => {
    set({
      view: 'default',
    });
  },
}));
