import { defineTokens } from '@chakra-ui/react';
export const colors = defineTokens.colors({
    black: {
        value: '#081020',
    },
    error: {
        value: '#FA3952',
    },
    warn: {
        value: '#FBBF10',
    },
    'primary-blue': {
        value: '#159DFF',
    },
    'secondary-dark-blue': {
        value: '#152C4B',
    },
    'secondary-sky-blue': {
        value: '#00DBDE',
    },
    cGray: {
        100: { value: '#D9D9D9' },
        200: { value: '#5F5F5F' },
        300: { value: '#E1E1E1' },
        400: { value: '#C2C2C2' },
    },
    cPurple: {
        50: { value: '#FBFAFF' },
        100: { value: 'rgba(128, 96, 210, 0.13)' },
        200: { value: 'rgba(128, 96, 210, 0.13)' },
        300: { value: '#42246D' },
        400: { value: '#9226FF' },
        500: { value: '#321B54' },
    },
    cLime: {
        100: { value: '#C5FB5B' },
    },
    cBlue: {
        500: { value: '#38CFFF' },
    },
    cOrange: {
        300: { value: '#F8B433' },
        400: { value: '#FFA409' },
        500: { value: '#F95E29' },
    },
    cGreen: {
        300: { value: '#27DD14' },
        500: { value: '#359D51' },
    },
    cYellow: {
        500: { value: '#FFF200' },
    },
});
