import { Dialog, Portal } from '@chakra-ui/react';
import { CloseButton } from '@ui/components/CloseButton';

import { DefaultContent } from './components/DefaultContent';
import { DisclaimerContent } from './components/DisclaimerContent';
import { useAccountModal } from './useAccountModal';

export const AccountModal = () => {
  const isOpen = useAccountModal((s) => s.isOpen);
  const closeModal = useAccountModal((s) => s.closeModal);
  const view = useAccountModal((s) => s.view);

  return (
    <Dialog.Root
      size="md"
      scrollBehavior="inside"
      open={isOpen}
      onEscapeKeyDown={closeModal}
      onInteractOutside={closeModal}
    >
      <Dialog.Backdrop onClick={closeModal} />
      <Portal>
        <Dialog.Positioner px={4}>
          <Dialog.Content>
            <CloseButton
              unstyled
              position="absolute"
              top={6}
              insetEnd={6}
              color="white"
              border="none"
              cursor="pointer"
              onClick={closeModal}
            />
            {view === 'default' ? <DefaultContent /> : null}
            {view === 'disclaimer' ? <DisclaimerContent /> : null}
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  );
};
