import type { ButtonProps, FlexProps } from '@chakra-ui/react';
import { Box, Flex } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import { calcInBn, trimDecimalsInStr } from '@ui/utils/helpers';
import numbro from 'numbro';
import { useCallback, useMemo } from 'react';

import WhiteButton from '../WhiteButton';

export type InputStepperProps = Omit<FlexProps, 'onChange'> & {
  onChange?: (value: string) => void;
  max: number | string;
  value?: string;
  steps?: number[];
  maxDecimals?: number;
};
export const InputStepper = ({
  onChange,
  max,
  steps = [0.25, 0.5, 0.75, 1],
  maxDecimals = 8,
  ...props
}: InputStepperProps) => {
  return (
    <Flex gap={3} justifyItems="space-between" {...props}>
      {steps.map((step, i) => (
        <Box flex={1} key={i}>
          <StepperButton
            max={max}
            ratio={step}
            maxDecimals={maxDecimals}
            onClick={onChange}
          />
        </Box>
      ))}
    </Flex>
  );
};

type StepperButtonProps = {
  max: number | string | BigNumber;
  ratio?: number;
  maxDecimals?: number;
  onClick?: (value: string) => void;
} & Omit<ButtonProps, 'onClick'>;

export const StepperButton = ({
  max,
  ratio = 1,
  maxDecimals,
  onClick,
  ...props
}: StepperButtonProps) => {
  const label = useMemo(() => {
    switch (ratio) {
      case 1:
        return 'Max';
      default:
        return numbro(ratio).format({
          output: 'percent',
        });
    }
  }, [ratio]);

  const getButtonValue = useCallback(() => {
    const result = calcInBn(max, (maxBn) => maxBn.mul(ratio * 1000).div(1000));
    return trimDecimalsInStr(result, maxDecimals);
  }, [max, ratio, maxDecimals]);

  const handleClick = useCallback(() => {
    if (onClick) onClick(getButtonValue());
  }, [onClick, getButtonValue]);

  return (
    <WhiteButton
      w="full"
      borderRadius="100px"
      textTransform="uppercase"
      onClick={handleClick}
      disabled={getButtonValue() === '0'}
      {...props}
    >
      {label}
    </WhiteButton>
  );
};
