import { Box } from '@chakra-ui/react';

interface RowHighlightEffectProps {
  highlighted?: boolean;
}

export const RowHighlightEffect = ({
  highlighted,
}: RowHighlightEffectProps) => (
  <Box
    boxSize="full"
    position="absolute"
    top={0}
    left={0}
    zIndex={-1}
    background="linear-gradient(90deg, #FBE85B 0%, #FF5E1A 50%, transparent 50%)"
    backgroundSize="200% 100%"
    backgroundPosition={highlighted ? 'left' : 'right'}
    opacity={highlighted ? 1 : 0}
    transition="all .5s ease"
  />
);
