import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import { create } from 'zustand';

interface UseCurrentVault {
  currentVault: VaultCardInfo | null;
  setCurrentVault: (vault: VaultCardInfo) => void;
  inputTokenAmount: string;
  setInputTokenAmount: (amount: string) => void;
}

export const useCurrentVault = create<UseCurrentVault>((set) => ({
  currentVault: null,
  setCurrentVault: (vault: VaultCardInfo) => set({ currentVault: vault }),
  inputTokenAmount: '',
  setInputTokenAmount: (amount: string) => set({ inputTokenAmount: amount }),
}));
