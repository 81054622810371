import { Center, HStack, Icon } from '@chakra-ui/react';
import { CapybaraToken } from '@cronos-app/icons';
import type { CustomNumberInputProps } from '@ui/components/Atoms/CustomNumberInput';
import { CustomNumberInput } from '@ui/components/Atoms/CustomNumberInput';
import { useState } from 'react';

export const CustomTokenInput = ({
  max,
  value,
  placeholder,
  onValueChange,
}: CustomNumberInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <HStack
      height="50px"
      position="relative"
      borderBottom="1px solid "
      borderColor={isFocused ? 'white' : 'cGray.200'}
      transition="border-color 0.2s"
    >
      <CustomNumberInput
        w="full"
        flexGrow={1}
        color="white"
        textStyle="h4"
        fontSize={{ base: '20px', lg: '31px' }}
        lineHeight={0}
        _placeholder={{ color: 'cGray.200' }}
        max={max}
        value={value}
        placeholder={placeholder}
        onValueChange={onValueChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <Center h="full" mb={2}>
        <Icon width="40px" height="40px">
          <CapybaraToken />
        </Icon>
      </Center>
    </HStack>
  );
};
