import { Flex, Text } from '@chakra-ui/react';
import type { AmountTokenUsdProps } from '@ui/components/Atoms/AmountUsd';
import { AmountTokenUsd } from '@ui/components/Atoms/AmountUsd';
import { InfoApiTokenSlug } from '@ui/config/urls';
import type { FC } from 'react';

type BaraAmountUsdProps = Omit<AmountTokenUsdProps, 'tokenSlug'>;

const BaraAmountUsd: FC<BaraAmountUsdProps> = ({ value, ...restProps }) => {
  return (
    <Flex color="white" gap={0} {...restProps} alignItems="center">
      <Text fontFamily="luckiestGuy" mt="4px">
        ~
      </Text>
      <AmountTokenUsd
        prefix="$"
        value={value}
        tokenSlug={InfoApiTokenSlug.Bara}
      />
    </Flex>
  );
};

export default BaraAmountUsd;
