import { List, Spacer, VStack } from '@chakra-ui/react';
import WhiteButton from '@ui/components/Atoms/WhiteButton';
import { DrawerBody, DrawerFooter } from '@ui/components/Organisms/Drawer';
import useBaraVaultDeposit from '@ui/hooks/contracts/BaraVault/useBaraVaultDeposit';
import { useCurrentVault } from '@ui/hooks/stores/useCurrentVault';
import { useStakeModal } from '@ui/hooks/stores/useStakeModalView';
import { captureVaultDeposit } from '@ui/utils/posthog/captureVaultActions';
import { useTranslations } from 'next-intl';

import StakeDetail from '../StakeDetail';

const ConfirmDepositModalView = () => {
  const { currentVault, inputTokenAmount } = useCurrentVault();

  const closeModal = useStakeModal((s) => s.closeModal);
  const { isLoading, mutateAsync } = useBaraVaultDeposit();

  const t = useTranslations();

  const handleOnNext = async () => {
    await mutateAsync(undefined, {
      onSuccess: () => {
        if (!currentVault) return;
        captureVaultDeposit(currentVault.pid, inputTokenAmount);
      },
    });
    closeModal();
  };

  return (
    <>
      <DrawerBody>
        <VStack h="full">
          {currentVault && <StakeDetail vault={currentVault} />}
          <Spacer />
          <List.Root
            px={{ base: 2, lg: 4 }}
            color="#E1E1E1"
            fontFamily="sans-serif"
            textStyle="caption2"
            listStylePosition="outside"
          >
            <List.Item>{t('common.Vault_Disclaimer_1')}</List.Item>
            <List.Item as="li">{t('common.Vault_Disclaimer_2')}</List.Item>
            <List.Item as="li">{t('common.Vault_Disclaimer_3')}</List.Item>
          </List.Root>
        </VStack>
      </DrawerBody>
      <DrawerFooter>
        <WhiteButton w="full" loading={isLoading} onClick={handleOnNext}>
          {t('common.CONFIRM & STAKE NOW')}
        </WhiteButton>
      </DrawerFooter>
    </>
  );
};

export default ConfirmDepositModalView;
