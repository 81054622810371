import type {
  BoxProps,
  ConditionalValue,  SeparatorProps as ChakraSeparatorProps} from '@chakra-ui/react';
import {
  Box,
  useBreakpointValue,
} from '@chakra-ui/react';
import { isObject } from 'lodash-es';

interface SeparatorProps extends Omit<BoxProps, 'width' | 'height'> {
  length?: string | number | ConditionalValue<string | number>;
  thickness?: string | number | ConditionalValue<string | number>;
  orientation: ChakraSeparatorProps['orientation'];
}

export const SilverSeparator = ({
  orientation,
  length = '100%',
  thickness = '1px',
  ...props
}: SeparatorProps) => {
  const _orientation = useBreakpointValue<SeparatorProps['orientation']>(
    isObject(orientation) ? orientation : { base: orientation },
  );
  const _length = useBreakpointValue<SeparatorProps['length']>(
    isObject(length) ? length : { base: length },
  );
  const _thickness = useBreakpointValue<SeparatorProps['thickness']>(
    isObject(thickness) ? thickness : { base: thickness },
  );

  const isHorizontal = _orientation === 'horizontal';

  return (
    <Box
      {...props}
      width={isHorizontal ? _length : _thickness}
      height={isHorizontal ? _thickness : _length}
      bgGradient={isHorizontal ? 'to-r' : 'to-b'}
      gradientFrom="rgba(255,255,255,0)"
      gradientVia="rgba(255,255,255,0.7)"
      gradientTo="rgba(255,255,255,0)"
      borderWidth="0px !important"
    />
  );
};
