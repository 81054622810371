import type { BigNumber } from '@ethersproject/bignumber';
import { useQuery } from '@tanstack/react-query';
import type { InfoApiTokenSlug } from '@ui/config/urls';
import { InfoApiTokenPriceUrl } from '@ui/config/urls';
import {
  convertTokenAmountBnToUsdValueBn,
  convertUsdPriceNumberToUsdPriceBn,
} from '@ui/utils/usd';

export const useUsdPrice = (slug: InfoApiTokenSlug) => {
  return useQuery<number>({
    queryKey: ['useUsdPrice', slug],
    queryFn: async () =>
      fetch(InfoApiTokenPriceUrl(slug))
        .then((res) => res.json())
        .catch((err) => console.error(err)),
  });
};

export const useConvertTokenValueBnToUsdPriceBn = (
  tokenBn: BigNumber,
  slug: InfoApiTokenSlug,
) => {
  const { data: price = 0, ...rest } = useUsdPrice(slug);

  const usdPriceBn = convertUsdPriceNumberToUsdPriceBn(price);

  return {
    data: convertTokenAmountBnToUsdValueBn(tokenBn, usdPriceBn),
    ...rest,
  };
};

export const useConvertMultipleTokenValueBnToUsdPriceBnList = (
  tokenBns: BigNumber[],
  slug: InfoApiTokenSlug,
) => {
  const { data: price = 0, ...rest } = useUsdPrice(slug);

  const usdPriceBn = convertUsdPriceNumberToUsdPriceBn(price);

  return {
    data: tokenBns.map((tokenBn) =>
      convertTokenAmountBnToUsdValueBn(tokenBn, usdPriceBn),
    ),
    ...rest,
  };
};
