import { create } from 'zustand';

type ViewType = 'select-deposit' | 'select-vault' | 'confirm-upgrade';

interface UseUpgradeModal {
  isOpen: boolean;
  view: ViewType;
  closeModal: () => void;
  openModal: (view: ViewType) => void;
  setView: (view: ViewType) => void;
}

export const useUpgradeModal = create<UseUpgradeModal>((set) => ({
  isOpen: false,
  view: 'select-deposit',
  closeModal: () => {
    set({ isOpen: false });
  },
  openModal: (view: ViewType) => {
    set({ isOpen: true, view });
  },
  setView: (view: ViewType) => {
    setTimeout(() => set({ view }), 10);
  },
}));
