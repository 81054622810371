import type { ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import Button from '@ui/components/Atoms/Button';
import { forwardRef } from 'react';

interface ButtonLoadingProps {
  loading?: boolean;
  loadingText?: React.ReactNode;
}

export interface ButtonProps extends ChakraButtonProps, ButtonLoadingProps {}

const WhiteButton = forwardRef<HTMLButtonElement, ButtonProps>(
  function UIButton(props, ref) {
    const { children, ...restProps } = props;
    return (
      <Button ref={ref} visual="white" {...restProps}>
        {children}
      </Button>
    );
  },
);

export default WhiteButton;
