import { defineTokens } from '@chakra-ui/react';
const generateSpaceFromRange = (start = 0.5, end = 100, step = 0.5) => {
    const res = {};
    for (let i = start; i < end; i += step) {
        res[String(i)] = {
            value: `${i / 4}rem`,
        };
    }
    return res;
};
export const spacing = defineTokens.spacing(generateSpaceFromRange());
