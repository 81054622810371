/**
 * checkbox theme
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%403.0.0/packages/react/src/theme/recipes/checkbox.ts
 */
import { defineSlotRecipe } from '@chakra-ui/react';
import { checkboxAnatomy } from '@chakra-ui/react/anatomy';
export const checkbox = defineSlotRecipe({
    slots: checkboxAnatomy.keys(),
    base: {},
    variants: {
        variant: {
            primary: {
                control: {
                    rounded: '4px',
                    width: '24px',
                    height: '24px',
                    border: 'unset',
                    bgColor: '#E2E0DB',
                    _checked: {
                        bgColor: 'cPurple.300',
                    },
                },
            },
            radio: {
                control: {
                    rounded: 'full',
                    width: '24px',
                    height: '24px',
                    border: 'unset',
                    bgColor: '#E2E0DB',
                    _icon: {
                        display: 'none',
                    },
                    _checked: {
                        bgColor: 'cPurple.300',
                        _before: {
                            content: '""',
                            display: 'block',
                            width: '50%',
                            height: '50%',
                            bgColor: 'white',
                            rounded: 'full',
                            position: 'absolute',
                            top: '49%',
                            left: '49%',
                            transform: 'translate(-50%, -50%)',
                        },
                    },
                },
            },
        },
        size: {},
    },
    compoundVariants: [],
    defaultVariants: { variant: 'primary' },
});
