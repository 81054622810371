import type { IntlProviderProps } from '@ts-intl/integration';
import type { AbstractIntlMessages } from 'next-intl';
import { IntlProvider } from 'next-intl';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { languageConfig } from './config';

export const IntlProviderWithPageProps = ({
  children,
  pageProps,
}: {
  children: ReactNode;
  pageProps: unknown;
}) => {
  const { currentLanguage, dictionary } = useMemo<IntlProviderProps>(() => {
    let defaultLanguage = languageConfig.defaultLanguage;
    let currentLanguage = defaultLanguage;
    let dictionary: AbstractIntlMessages = {};
    if (pageProps && typeof pageProps === 'object') {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const i18nProps = pageProps as Partial<IntlProviderProps>;
      if (typeof i18nProps.defaultLanguage === 'string') {
        defaultLanguage = i18nProps.defaultLanguage;
      }
      if (typeof i18nProps.currentLanguage === 'string') {
        currentLanguage = i18nProps.currentLanguage;
      }
      if (i18nProps.dictionary && typeof i18nProps.dictionary === 'object') {
        dictionary = i18nProps.dictionary;
      }
    }
    return {
      defaultLanguage,
      currentLanguage,
      dictionary,
    };
  }, [pageProps]);
  return (
    <IntlProvider
      locale={currentLanguage}
      messages={dictionary}
      getMessageFallback={getMessageFallback}
      onError={onError}
    >
      {children}
    </IntlProvider>
  );
};

const onError: Parameters<typeof IntlProvider>[0]['onError'] = () => {
  // not console.error missing translations
};
const getMessageFallback: Parameters<
  typeof IntlProvider
>[0]['getMessageFallback'] = ({ key }) => {
  // directly return the key as the fallback message
  return key;
};
