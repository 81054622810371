import { HStack, Icon, Text } from '@chakra-ui/react';
import { CheckCircle, GradientCheckCircle } from '@cronos-app/icons';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';

interface DepositUnlockLabelProps {
  isInVaultCard: boolean;
}

const DepositUnlockLabel: FC<DepositUnlockLabelProps> = ({ isInVaultCard }) => {
  const t = useTranslations();

  if (isInVaultCard) {
    return (
      <HStack
        background="linear-gradient(90deg, #FBE85B 0%, #FF5E1A 100%)"
        py={2}
        px={{ base: 2, lg: 4 }}
        w="full"
        gapX={{ base: 1, lg: 2 }}
        fontFamily="luckiestGuy"
      >
        <Icon color="black" boxSize={{ base: 5, lg: 6 }}>
          <CheckCircle />
        </Icon>
        <Text color="black" fontSize={{ base: 12, lg: 16 }} mt={1}>
          {t('common.Deposit Unlocked')}
        </Text>
      </HStack>
    );
  }

  return (
    <HStack fontFamily="luckiestGuy">
      <Icon>
        <GradientCheckCircle />
      </Icon>
      <Text
        background="linear-gradient(90deg, #FBE85B 0%, #FF5E1A 100%)"
        backgroundClip="text"
        WebkitTextFillColor="transparent"
      >
        {t('common.Deposit Unlocked')}
      </Text>
    </HStack>
  );
};

export default DepositUnlockLabel;
