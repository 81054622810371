'use client';

import {
  Box,
  createToaster,
  Link,
  Portal,
  Text,
  Toast,
  Toaster as ChakraToaster,
  VStack,
} from '@chakra-ui/react';
import { explorers, useDesiredChain } from '@cronos-app/wallet';
import { useTranslations } from 'next-intl';

export const toaster = createToaster({
  placement: 'top-end',
  pauseOnPageIdle: true,
  duration: 3000,
});

export enum TOAST_STATUS {
  INFO = 'info',
  LOADING = 'loading',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

const toastStatusToColorMap: Record<TOAST_STATUS, string> = {
  [TOAST_STATUS.INFO]: '#3C79EB',
  [TOAST_STATUS.LOADING]: '#3C79EB',
  [TOAST_STATUS.WARNING]: '#FF9906',
  [TOAST_STATUS.SUCCESS]: '#359D51',
  [TOAST_STATUS.ERROR]: '#E33F34',
} as const;

export const Toaster = () => {
  const t = useTranslations();

  const chainId = useDesiredChain();
  const BLOCK_EXPLORER_URL = explorers[chainId];

  return (
    <Portal>
      <ChakraToaster toaster={toaster} insetInline={{ mdDown: '4' }}>
        {(toast) => {
          let borderColor = 'black';
          if (toast.type && toast.type in toastStatusToColorMap) {
            borderColor =
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              toastStatusToColorMap[toast.type as unknown as TOAST_STATUS];
          }

          return (
            <Toast.Root bg="transparent" width={{ md: 'sm' }}>
              <Box
                w={{ base: 'full', lg: 96 }}
                bgColor="#000000"
                p={3}
                borderRadius="lg"
              >
                <Box
                  minH={6}
                  position="relative"
                  borderLeft="4px solid"
                  borderLeftColor={borderColor}
                  pl={3}
                >
                  <VStack pt={2} pr={8} align="start">
                    {toast.title && (
                      <Toast.Title color="white" fontSize={16}>
                        {toast.title}
                      </Toast.Title>
                    )}
                    {toast.description && (
                      <Toast.Description
                        color="white"
                        fontFamily="sans-serif"
                        fontWeight="bold"
                      >
                        {toast.description}
                      </Toast.Description>
                    )}
                    {toast.meta?.txHash && BLOCK_EXPLORER_URL && (
                      <Link
                        variant="plain"
                        href={`${BLOCK_EXPLORER_URL}/tx/${toast.meta.txHash}`}
                        target="_blank"
                        mt={2}
                      >
                        <Text color="white" textStyle="caption1">
                          {t('common.View on Cronoscan')}
                        </Text>
                      </Link>
                    )}
                  </VStack>

                  {toast.action && (
                    <Toast.ActionTrigger color="white" border={0} px="0">
                      {toast.action.label}
                    </Toast.ActionTrigger>
                  )}
                  {toast.meta?.closable === false ? null : (
                    <Toast.CloseTrigger boxSize="16px" p="0" cursor="pointer" />
                  )}
                </Box>
              </Box>
            </Toast.Root>
          );
        }}
      </ChakraToaster>
    </Portal>
  );
};
