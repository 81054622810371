import { Center, Text } from '@chakra-ui/react';
import { ChainId } from '@cronos-app/sdk';
import { useDesiredChain, useGlobalContextStore } from '@cronos-app/wallet';
import { useMemo } from 'react';

/**
 * experimental feature
 * @returns
 */
export const GlobalChainSelect = () => {
  const chainId = useDesiredChain();
  const index = useMemo(() => CHAINS.indexOf(chainId), [chainId]);
  return (
    <Center
      fontWeight="bold"
      color="white"
      cursor="pointer"
      rounded="4px"
      bgColor="rgba(23, 24, 26, 0.75)"
      onClick={(e) => {
        useGlobalContextStore.setState({
          chainId: CHAINS[(index + 1) % CHAINS.length],
        });
        e.stopPropagation();
      }}
    >
      <Text>{chainId}</Text>
    </Center>
  );
};

const CHAINS = [ChainId.CRONOS_MAINNET, ChainId.CRONOS_TESTNET];
