import { Box, Flex, Text } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { StakeInfoToken } from '@ui/components/Atoms/StakaInfoToken';
import { AmountInput } from '@ui/components/Molecules/AmountInput';
import BaraAmountUsd from '@ui/components/Molecules/BaraAmountUsd';
import { BARA_DECIMALS } from '@ui/config/sc';
import { useBaraBalance } from '@ui/hooks/contracts/useBaraBalance';
import { useCurrentVault } from '@ui/hooks/stores/useCurrentVault';
import { Fraction } from 'bi-fraction';
import { useTranslations } from 'next-intl';

export const DepositInput = () => {
  const t = useTranslations();
  const { data } = useBaraBalance();
  const { setInputTokenAmount, inputTokenAmount } = useCurrentVault();

  const inputTokenInt = new Fraction(inputTokenAmount).shl(
    BARA_DECIMALS,
  ).quotient;

  return (
    <Box w="full">
      <Flex color="#C377FF" mb={3} justify="space-between">
        <Text textStyle="subTitle2">{t('common.Deposit Amount')}</Text>
        <BaraAmountUsd color="#C377FF" value={BigNumber.from(inputTokenInt)} />
      </Flex>
      <AmountInput
        max={
          data?.totalNumber
            ? formatUnits(data.totalNumber, BARA_DECIMALS)
            : undefined
        }
        value={inputTokenAmount.toString()}
        maxDecimals={BARA_DECIMALS}
        placeholder={t('common.INPUT AMOUNT HERE')}
        onChange={setInputTokenAmount}
      />
      <Flex mt={4} justify="space-between">
        <Text textStyle="subTitle2" color="white">
          {t('common.In Your Wallet')}
        </Text>
        <StakeInfoToken
          value={data?.totalNumber}
          textProps={{ textStyle: 'subTitle2', color: 'white' }}
          isHideUsd
        />
      </Flex>
    </Box>
  );
};
