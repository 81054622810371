import { Dialog, Flex, Portal, Separator } from '@chakra-ui/react';
import {
  currentWallet,
  getAddEthereumChainParameter,
  useDesiredChain,
  walletConfigs,
} from '@cronos-app/wallet';
import { CloseButton } from '@ui/components/CloseButton';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';
import { Fragment } from 'react';
import { create } from 'zustand';

import { WalletCard } from './WalletCard';

interface UseWalletModal {
  isOpen: boolean;
  closeModal: () => void;
  openModal: () => void;
}

export const useWalletModal = create<UseWalletModal>((set) => ({
  isOpen: false,
  closeModal: () => {
    set({ isOpen: false });
  },
  openModal: () => {
    set({ isOpen: true });
  },
}));

export const WalletModal: FC<unknown> = () => {
  const t = useTranslations();
  const chainId = useDesiredChain();

  const isOpen = useWalletModal((s) => s.isOpen);
  const closeModal = useWalletModal((s) => s.closeModal);

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Backdrop />
      <Portal>
        <Dialog.Positioner px={{ base: 2, lg: 0 }}>
          <Dialog.Content>
            <Dialog.Header textStyle="h4">
              {t('common.Connect Wallet')}
            </Dialog.Header>
            <CloseButton
              visual="none"
              position="absolute"
              top="2"
              insetEnd="2"
              onClick={closeModal}
            />
            <Dialog.Body pb={6}>
              <Flex direction="column" gap={2}>
                {walletConfigs.map((v) => (
                  <Fragment key={v.name}>
                    <WalletCard
                      onClick={() => {
                        closeModal();
                        currentWallet.connectAsCurrentWallet(
                          v.name,
                          getAddEthereumChainParameter(chainId),
                        );
                      }}
                      walletConfig={v}
                    />
                    <Separator />
                  </Fragment>
                ))}
              </Flex>
            </Dialog.Body>
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  );
};
