import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { BARA_DECIMALS } from '@ui/config/sc';
import type { Stake } from '@ui/interfaces/stake.interface';
import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import { estimateReward } from '@ui/utils/vault';

export const useUpgradeRewards = (stakes: Stake[], vault: VaultCardInfo) => {
  const now = Math.round(new Date().getTime() / 1000);
  const { apr, lockPeriod = BigNumber.from(0) } = vault;

  const stakeLockTimes = stakes.map((stake) =>
    stake.stakeTimestamp.add(lockPeriod).sub(now),
  );

  const stakeRewards = stakeLockTimes.map((time, i) =>
    estimateReward(
      Number(formatUnits(stakes[i].amount, BARA_DECIMALS)),
      time.toNumber(),
      apr,
    ),
  );

  const totalReward = stakeRewards.reduce(
    (acc, reward) => (acc ?? 0) + (reward ?? 0),
    0,
  );

  return { stakeRewards, totalReward };
};
