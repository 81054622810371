import { Flex } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import { Amount } from '@ui/components/Atoms/Amount';
import { RowHighlightSelect } from '@ui/components/Atoms/RowHighlightSelect';
import UnlockDate from '@ui/components/Atoms/UnlockDate';
import type { Stake } from '@ui/interfaces/stake.interface';

import BaraAmountUsd from '../BaraAmountUsd';

interface DepositSelectionProps {
  stakes: Stake[];
  selectedIds?: Stake['stakeId'][];
  onSelectStake?: (stake: Stake) => void;
}

export const DepositSelection = ({
  stakes = [],
  selectedIds = [],
  onSelectStake = () => {},
}: DepositSelectionProps) => {
  const isSelected = (stakeId: BigNumber) =>
    selectedIds.some((id) => id.eq(stakeId));

  return (
    <>
      {stakes.map((stake) => (
        <RowHighlightSelect
          key={stake.stakeId.toString()}
          isSelected={isSelected(stake.stakeId)}
          onSelect={() => onSelectStake(stake)}
        >
          <Flex flexDir="column" pt={2}>
            <Amount
              value={stake.amount}
              decimals={18}
              textStyle="h5"
              fontSize={{ base: 20, lg: 24 }}
              suffix=" BARA"
            />
            <BaraAmountUsd
              value={stake.amount}
              color="unset"
              fontSize={{ base: 16, lg: 18 }}
            />

            <UnlockDate
              isInStakeModal
              unlockTimestamp={stake.unlockTimestamp}
            />
          </Flex>
        </RowHighlightSelect>
      ))}
    </>
  );
};
