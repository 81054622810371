import { currentWallet as wallet } from '@cronos-app/wallet';
import {
  posthog,
  useInitPostHog,
  useInitPostHogStore,
  useTrackUserByWallet,
} from '@cronos-labs/react-posthog';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useInitializePostHog = () => {
  useInitPostHog();
  useInitPostHogStore();
  usePostHogTrackRoute();
  useTrackUserByWallet(wallet);
};

export const usePostHogTrackRoute = () => {
  const router = useRouter();

  useEffect(() => {
    if (posthog === undefined) return;

    const handleRouteChange = () => {
      if (typeof window !== 'undefined') {
        posthog.capture('$pageview');
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};
