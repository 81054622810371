import { button } from './button';
import { checkbox } from './checkbox';
import { dialog } from './dialog';
import { drawer } from './drawer';
import { link } from './link';
import { menu } from './menu';
/**
 * slotRecipes theme
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%403.0.0/packages/react/src/theme/slot-recipes.ts
 */
export const slotRecipes = {
    dialog,
    drawer,
    menu,
    checkbox,
};
/**
 * recipes theme
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%403.0.0/packages/react/src/theme/recipes.ts
 */
export const recipes = {
    button,
    link,
};
