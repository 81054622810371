import type { Stake } from '@ui/interfaces/stake.interface';
import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import type { Dispatch, FC, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useState } from 'react';

interface IUpgradeVaultContext {
  stakes: Stake[];
  vault: VaultCardInfo | undefined;
  setStakes: Dispatch<SetStateAction<Stake[]>>;
  setVault: Dispatch<SetStateAction<VaultCardInfo | undefined>>;
  reset: () => void;
}

const UpgradeVaultContext = createContext<IUpgradeVaultContext | undefined>(
  undefined,
);

export const UpgradeVaultProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [stakes, setStakes] = useState<Stake[]>([]);
  const [vault, setVault] = useState<VaultCardInfo>();

  const reset = () => {
    setStakes([]);
  };

  return (
    <UpgradeVaultContext.Provider
      value={{ stakes, vault, setStakes, setVault, reset }}
    >
      {children}
    </UpgradeVaultContext.Provider>
  );
};

export const useUpgradeVault = () => {
  const context = useContext(UpgradeVaultContext);
  if (!context) {
    throw new Error(
      'useUpgradeVault must be used within an UpgradeVaultProvider',
    );
  }
  return context;
};
