import type { IconButtonProps } from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/react';

interface HeaderButtonProps extends IconButtonProps {
  isWithdraw: boolean;
}

export const HeaderButton = ({
  isWithdraw,
  children,
  ...props
}: HeaderButtonProps) => {
  return (
    <IconButton
      size="sm"
      unstyled
      cursor="pointer"
      color={isWithdraw ? 'cPurple.500' : 'white'}
      _hover={isWithdraw ? { color: 'white' } : { color: '#C377FF' }}
      {...props}
    >
      {children}
    </IconButton>
  );
};
