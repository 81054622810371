import {
  Box,
  DrawerBody,
  DrawerFooter,
  List,
  Separator,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import Button from '@ui/components/Atoms/Button';
import { useUpgradeVault } from '@ui/contexts/UpgradeVaultContext';
import { useBaraVaultUpgrade } from '@ui/hooks/contracts/BaraVault/useBaraVaultUpgrade';
import { useUpgradeModal } from '@ui/hooks/stores/useUpgradeModalView';
import { captureVaultUpgrade } from '@ui/utils/posthog/captureVaultActions';
import { useTranslations } from 'next-intl';

import { VaultSelection } from '../SelectUpgradeVaultModalView/VaultSelection';
import { UpgradeStakeDetails } from './UpgradeStakeDetails';

export const ConfirmUpgradeModalView = () => {
  const t = useTranslations();

  const { stakes, vault } = useUpgradeVault();
  const { closeModal } = useUpgradeModal();
  const { mutate, isLoading } = useBaraVaultUpgrade();

  const handleConfirmUpgrade = async () => {
    if (!vault) return;
    const stakeIds: string[] = [];
    const amount = BigNumber.from(0);

    stakes.forEach((stake) => {
      stakeIds.push(stake.stakeId.toString());
      amount.add(stake.amount);
    });

    mutate(
      { stakes, vault },
      {
        onSuccess: () => {
          captureVaultUpgrade(stakes, vault);
          closeModal();
        },
      },
    );
  };

  return (
    <>
      <DrawerBody>
        <VStack w="full" align="start" spaceY={{ base: 3, lg: 6 }}>
          <VStack w="full" align="start" spaceY={{ base: 0, lg: 3 }}>
            <Text textStyle="h5">{t('common.UPGRADED NEW VAULT')}</Text>
            {vault && <VaultSelection vault={vault} stakes={stakes} />}
          </VStack>

          <Separator size="lg" borderColor="#42246D" />

          <VStack w="full" align="start" spaceY={{ base: 1, lg: 3 }}>
            <Text textStyle="h5">{t('common.LOCK DATE UPDATE')}</Text>
            {vault &&
              stakes.map((stake) => (
                <Box key={stake.stakeId.toString()}>
                  <UpgradeStakeDetails stake={stake} vault={vault} />
                </Box>
              ))}
          </VStack>
        </VStack>
      </DrawerBody>
      <DrawerFooter bgColor="#42246D" pt={5}>
        <VStack spaceY={3}>
          <List.Root
            color="white"
            fontFamily="footer"
            px={3}
            spaceY={1}
            fontSize={{ base: 10, lg: 12 }}
            lineHeight={{ base: 1.5, lg: 1.5 }}
          >
            <List.Item listStylePosition="outside">
              {t('common.Vault_Upgrade_1')}
            </List.Item>
            <List.Item listStylePosition="outside">
              {t('common.Vault_Upgrade_2')}
            </List.Item>
          </List.Root>
          <Button
            w="full"
            visual="primary-light"
            fontFamily="luckiestGuy"
            loading={isLoading}
            onClick={handleConfirmUpgrade}
          >
            {t('common.CONFIRM UPGRADE')}
          </Button>
        </VStack>
      </DrawerFooter>
    </>
  );
};
