import {
  defineConfig,
  defineGlobalStyles,
  defineTokens,
} from '@chakra-ui/react';

export const fontFamilyVariable = {
  default: '--cronos-font-default',
  heading: '--cronos-font-heading',
  slogan: '--cronos-font-slogan',
  footer: '--cronos-font-footer',
  luckiestGuy: '--cronos-font-luckiest-guy',
} as const;

const fonts = defineTokens.fonts({
  default: {
    value: `var(${fontFamilyVariable.default})`,
  },
  heading: {
    value: `var(${fontFamilyVariable.heading})`,
  },
  slogan: {
    value: `var(${fontFamilyVariable.slogan})`,
  },
  footer: {
    value: `var(${fontFamilyVariable.footer})`,
  },
  luckiestGuy: {
    value: `var(${fontFamilyVariable.luckiestGuy})`,
  },
});

const fontGlobalStyles = defineGlobalStyles({
  body: {
    fontFamily: 'default',
  },
  'd1,d2,h1,h2,h3': {
    fontFamily: 'heading',
  },
});

export const fontChakraConfig = defineConfig({
  theme: {
    tokens: {
      fonts,
    },
  },
  globalCss: fontGlobalStyles,
});
