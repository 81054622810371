import type { BaraVault } from '@cronos-app/sdk';
import { useDesiredChain } from '@cronos-app/wallet';
import { getSDK } from '@ui/utils';
import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';

interface IBaraVaultContractContext {
  baraVaultContract: BaraVault | null;
}

export const BaraVaultContractContext =
  createContext<IBaraVaultContractContext>({
    baraVaultContract: null,
  });

export const BaraVaultContractProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const desiredChainId = useDesiredChain();
  const sdk = getSDK(desiredChainId);

  const baraVaultContract = sdk.getBaraVault();

  return (
    <BaraVaultContractContext.Provider value={{ baraVaultContract }}>
      {children}
    </BaraVaultContractContext.Provider>
  );
};

export const useBaraVaultContract = () => {
  return useContext<IBaraVaultContractContext>(BaraVaultContractContext);
};
