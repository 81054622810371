import { BigNumber } from '@ethersproject/bignumber';
import type { QueryKey } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useBaraVaultContract } from '@ui/contexts/BaraVaultContractContext';
import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import { expandDecimals } from '@ui/utils/formatNumber';
import { useTranslations } from 'next-intl';

import type { BaraVaultUserInfo } from './useBaraVaultUserInfo';
import useBaraVaultUserInfo from './useBaraVaultUserInfo';

export const SECONDS_PER_YEAR = 31536000;
export const DIGITS = 4;

export const getUseBaraVaultInfoListQueryKey = (
  userInfoData: BaraVaultUserInfo | undefined,
): QueryKey => ['BaraVault.vaultInfoList', userInfoData];

const useVaultInfoList = () => {
  const t = useTranslations();
  const lockPeriodMap = {
    '0': t('common.3 months'),
    '1': t('common.6 months'),
    '2': t('common.12 months'),
  };
  const { baraVaultContract } = useBaraVaultContract();
  const { data: userInfoData } = useBaraVaultUserInfo();

  return useQuery<VaultCardInfo[]>(
    getUseBaraVaultInfoListQueryKey(userInfoData),
    async () => {
      if (!baraVaultContract) {
        return [];
      }

      try {
        const poolCounts = await baraVaultContract.poolLength();
        const rewardsPerSecond = await baraVaultContract.rewardPerSecond();
        const rewardPerYear =
          BigNumber.from(rewardsPerSecond).mul(SECONDS_PER_YEAR);
        const rawPoolInfos = await Promise.all(
          [...Array(Number(poolCounts)).keys()].map((pid) =>
            baraVaultContract.poolInfo(pid),
          ),
        );
        const totalWeightedStaked = rawPoolInfos.reduce(
          (acc, curr) =>
            acc.add(BigNumber.from(curr.totalAmount).mul(curr.multiplier)),
          BigNumber.from(0),
        );
        return rawPoolInfos.map<VaultCardInfo>(
          ({ multiplier, lockPeriod, totalAmount }, i) => {
            const totalAmountBn = BigNumber.from(totalAmount);
            const weightedStaked = BigNumber.from(totalAmount).mul(multiplier);

            let apr = null;
            if (totalWeightedStaked.gt(0) && totalAmountBn.gt(0)) {
              const numerator = rewardPerYear
                .mul(weightedStaked)
                .mul(expandDecimals(DIGITS));

              const denominator = totalWeightedStaked.mul(totalAmountBn);

              apr = Number(numerator.div(denominator)) / 10 ** DIGITS;
            }

            return {
              pid: i,
              apr,
              totalAmount: BigNumber.from(totalAmount), // Convert bigint to BigNumber
              multiplier: Number(multiplier), // Convert bigint to number
              lockPeriod: Number(lockPeriod),
              lockPeriodTitle:
                // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                lockPeriodMap[i as unknown as keyof typeof lockPeriodMap],
              totalStaked:
                userInfoData?.poolTotalStakeMap[i] ?? BigNumber.from(0),
            };
          },
        );
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    {
      enabled: !!baraVaultContract,
    },
  );
};

export default useVaultInfoList;
