/**
 * button theme
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%403.0.0/packages/react/src/theme/recipes/button.ts
 */
import { defineRecipe } from '@chakra-ui/react';
const primary = {
    color: 'white',
    border: '1px solid #B67CF4',
    backdropFilter: 'blur(7px)',
    verticalAlign: 'middle',
    textTransform: 'uppercase',
    boxShadow: '0px 0px 14px 0px #DCB9FF',
    background: 'linear-gradient(0deg, #401782 0%, #722EB7 100%)',
    fontFamily: 'luckiestGuy',
};
const whiteStyle = {
    color: '#42246D',
    border: '1px solid transparent',
    verticalAlign: 'middle',
    textTransform: 'uppercase',
    background: 'white',
    boxShadow: '0px 0px 14px 0px #DCB9FF',
    fontFamily: 'luckiestGuy',
    _hover: {
        color: '#42246D',
        background: 'white',
        boxShadow: '0px 0px 20px 0px #EDDBFF',
        transition: 'all 0.1s ease-in',
    },
};
export const button = defineRecipe({
    base: {},
    variants: {
        visual: {
            primary: {
                ...primary,
                _hover: {
                    boxShadow: '0px 0px 20px 0px #EDDBFF',
                    background: 'linear-gradient(0deg, #4f2a8c 0%, #7938bb 100%)',
                    transition: 'all 0.1s ease-in',
                },
            },
            'primary-light': {
                ...primary,
                background: 'white',
                color: 'cPurple.300',
                borderRadius: 'full',
                _hover: {
                    background: 'white',
                    boxShadow: '0px 0px 20px 0px #EDDBFF',
                    transition: 'all 0.1s ease-in',
                },
            },
            'primary-outline': {
                ...primary,
                background: 'transparent',
                borderColor: 'white',
                boxShadow: 'unset',
                borderRadius: 'full',
                _hover: {
                    background: 'rgba(121, 56, 187, 0.4)',
                    transition: 'background 0.2s ease-in',
                },
            },
            white: {
                ...whiteStyle,
                px: { base: 4, lg: 5 },
                pt: { base: 1, lg: 4 },
                pb: { base: 0.5, lg: 3 },
                textStyle: { base: 'body1', lg: 'h5' },
                borderRadius: { base: 'xl', lg: '2xl' },
            },
            none: {},
        },
        size: {
            sm: {
                px: { base: 4, lg: 6 },
                pt: { base: 3, lg: 4 },
                pb: { base: 2, lg: 3 },
                textStyle: { base: 'h6', lg: 'h5' },
                borderRadius: 'xl',
            },
            md: {
                px: { base: 6, lg: 8 },
                pt: { base: 2, lg: 7 },
                pb: { base: 1, lg: 6 },
                textStyle: { base: 'h5', lg: 'h4' },
                borderRadius: 'xl',
            },
            lg: {
                px: { base: 8, lg: 8 },
                pt: { base: 3, lg: 10 },
                pb: { base: 2, lg: 9 },
                textStyle: { base: 'h4', lg: 'h3' },
                borderRadius: '3xl',
            },
        },
    },
    compoundVariants: [],
    defaultVariants: { visual: 'primary', size: 'md' },
});
