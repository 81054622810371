import { Fraction } from 'bi-fraction';

import { useBaraBalance } from './contracts/useBaraBalance';
import { useCurrentVault } from './stores/useCurrentVault';

const useIsEnoughBalance = () => {
  const { data: baraData } = useBaraBalance();
  const { inputTokenAmount } = useCurrentVault();
  if (!baraData) {
    return false;
  }

  if (baraData.totalNumber.isZero()) {
    return false;
  }

  const input = new Fraction(inputTokenAmount);

  if (input.eq(0)) {
    return false;
  }

  return baraData.totalAmount.gte(input);
};

export default useIsEnoughBalance;
