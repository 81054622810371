/**
 * dialog theme
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%403.0.0/packages/react/src/theme/recipes/dialog.ts
 */
import { defineSlotRecipe } from '@chakra-ui/react';
import { dialogAnatomy } from '@chakra-ui/react/anatomy';
export const dialog = defineSlotRecipe({
    slots: dialogAnatomy.keys(),
    base: {},
    variants: {
        scrollBehavior: {
            inside: {},
            outside: {},
        },
        size: {
            full: {
                content: {
                    /**
                     * Fix missing modal footer on iOS.
                     * @see https://github.com/chakra-ui/chakra-ui/issues/4903
                     */
                    minH: '100dvh',
                },
            },
        },
    },
    compoundVariants: [],
    defaultVariants: {},
});
