import type { FlexProps } from '@chakra-ui/react';
import { Box, Flex, Skeleton, Text } from '@chakra-ui/react';
import type { ReactNode } from 'react';

export type TwoColumnInfoRowProps = {
  title: string;
  value?: ReactNode;
} & Omit<FlexProps, 'title'>;

export const TwoColumnInfoRow = ({
  title,
  value,
  ...flexProps
}: TwoColumnInfoRowProps) => {
  return (
    <Flex justify="space-between" {...flexProps}>
      <Text color="cGray.300" textStyle="body-2">
        {title}
      </Text>
      <Skeleton loading={!value}>
        <Box textStyle="subtitle-1" color="white">
          {value ?? '--'}
        </Box>
      </Skeleton>
    </Flex>
  );
};
