import { Flex } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';

import type { CheckboxProps } from '../Checkbox';
import { Checkbox } from '../Checkbox';
import { RowHighlightEffect } from '../RowHighlightEffect';

export interface RowHighlightSelectProps extends PropsWithChildren {
  variant?: CheckboxProps['variant'];
  isSelected?: boolean;
  onSelect?: (isSelected: boolean) => void;
}

export const RowHighlightSelect = ({
  variant = 'primary',
  isSelected,
  onSelect,
  children,
}: RowHighlightSelectProps) => {
  return (
    <Flex
      w="full"
      position="relative"
      justify="space-between"
      py={1.5}
      px={3}
      borderLeft="2px solid"
      borderColor="cPurple.300"
      cursor={onSelect ? 'pointer' : 'unset'}
      onClick={() => onSelect?.(!isSelected)}
    >
      {children}
      {onSelect && (
        <Flex align="center" pointerEvents="none">
          <Checkbox size="lg" variant={variant} checked={isSelected} />
        </Flex>
      )}
      <RowHighlightEffect highlighted={isSelected} />
    </Flex>
  );
};
