/**
 * drawer theme
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%403.0.0/packages/react/src/theme/recipes/drawer.ts
 */
import { defineSlotRecipe } from '@chakra-ui/react';
import { drawerAnatomy } from '@chakra-ui/react/anatomy';
export const drawer = defineSlotRecipe({
    slots: drawerAnatomy.keys(),
    base: {},
    variants: {},
    compoundVariants: [],
    defaultVariants: {},
});
