import { VStack } from '@chakra-ui/react';
import WhiteButton from '@ui/components/Atoms/WhiteButton';
import { DepositInput } from '@ui/components/Organisms/DepositInput';
import { DrawerBody, DrawerFooter } from '@ui/components/Organisms/Drawer';
import { useCurrentVault } from '@ui/hooks/stores/useCurrentVault';
import { useStakeModal } from '@ui/hooks/stores/useStakeModalView';
import useIsEnoughBalance from '@ui/hooks/useIsEnoughBalance';
import { useTranslations } from 'next-intl';

import { EstimatedRewardCard } from '../EstimatedRewardCard';
import StakeDetail from '../StakeDetail';

const DepositInputModalView = () => {
  const currentVault = useCurrentVault((s) => s.currentVault);
  const setView = useStakeModal((s) => s.setView);
  const isEnoughBalance = useIsEnoughBalance();
  const t = useTranslations();

  const handleOnNext = () => {
    if (isEnoughBalance) {
      setView('confirmDeposit');
    }
  };

  return (
    <>
      <DrawerBody>
        <VStack>
          <DepositInput />
          <EstimatedRewardCard />
          {currentVault && <StakeDetail vault={currentVault} />}
        </VStack>
      </DrawerBody>
      <DrawerFooter>
        <WhiteButton
          disabled={!isEnoughBalance}
          w="full"
          onClick={handleOnNext}
        >
          {t('common.NEXT & REVIEW')}
        </WhiteButton>
      </DrawerFooter>
    </>
  );
};

export default DepositInputModalView;
