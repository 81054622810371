import {
  DrawerBackdrop,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerHeader,
  DrawerRoot,
  HStack,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { ChevronLeft, Close } from '@cronos-app/icons';
import { ConfirmUpgradeModalView } from '@ui/components/Molecules/ConfirmUpgradeModalView';
import { SelectUpgradeDepositModalView } from '@ui/components/Molecules/SelectUpgradeDepositModalView';
import { SelectUpgradeVaultModalView } from '@ui/components/Molecules/SelectUpgradeVaultModalView';
import { UpgradeVaultProvider } from '@ui/contexts/UpgradeVaultContext';
import { useStakeModal } from '@ui/hooks/stores/useStakeModalView';
import { useUpgradeModal } from '@ui/hooks/stores/useUpgradeModalView';
import { useTranslations } from 'next-intl';

const StakeModal = () => {
  const t = useTranslations();

  const [isOpen, view] = useUpgradeModal((s) => [s.isOpen, s.view]);
  const setView = useUpgradeModal((s) => s.setView);
  const closeModal = useUpgradeModal((s) => s.closeModal);

  const openVaultModal = useStakeModal((s) => s.openModal);

  const onClickBack = () => {
    switch (view) {
      case 'select-deposit':
        closeModal();
        openVaultModal('currentVault');
        break;
      case 'select-vault':
        setView('select-deposit');
        break;
      case 'confirm-upgrade':
        setView('select-vault');
        break;
    }
  };

  const onCloseModal = () => {
    closeModal();
  };

  return (
    <DrawerRoot
      size={{ base: 'full', lg: 'md' }}
      open={isOpen}
      onOpenChange={onCloseModal}
      placement="end"
    >
      <DrawerBackdrop onClick={onCloseModal} />
      <DrawerContent
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        background="#CB8AFF"
        color="#42246D"
      >
        <UpgradeVaultProvider>
          <DrawerHeader>
            <HStack alignItems="center">
              <IconButton
                unstyled
                p={2}
                cursor="pointer"
                _icon={{ boxSize: 6 }}
                onClick={onClickBack}
              >
                <ChevronLeft color="#42246D" />
              </IconButton>
              <Text
                fontSize="2xl"
                fontWeight="bold"
                mx="auto"
                fontFamily="luckiestGuy"
              >
                {t('common.UPGRADE')}
              </Text>
              <DrawerCloseTrigger cursor="pointer">
                <IconButton
                  unstyled
                  p={2}
                  cursor="pointer"
                  _icon={{ boxSize: 8 }}
                >
                  <Close />
                </IconButton>
              </DrawerCloseTrigger>
            </HStack>
          </DrawerHeader>
          {view === 'select-deposit' && <SelectUpgradeDepositModalView />}
          {view === 'select-vault' && <SelectUpgradeVaultModalView />}
          {view === 'confirm-upgrade' && <ConfirmUpgradeModalView />}
        </UpgradeVaultProvider>
      </DrawerContent>
    </DrawerRoot>
  );
};

export default StakeModal;
