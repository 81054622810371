import { defineTokens } from '@chakra-ui/react';
export const gradients = defineTokens.gradients({
    'sky-blue-dark-blue': {
        value: 'linear-gradient(90deg, #00DBDE 0%, #152C4B 100%)',
    },
    'blue-purple': {
        value: 'linear-gradient(90deg, #00DBDE 0%, #FC00FF 100%)',
    },
    'green-blue-circle': {
        value: 'radial-gradient(circle, rgba(157,155,66,1) 0%, rgba(33,121,9,1) 15%, rgba(0,212,255,1) 100%)',
    },
    rainbow: {
        value: 'linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)',
    },
});
