/**
 * menu theme
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%403.0.0/packages/react/src/theme/recipes/menu.ts
 */
import { defineSlotRecipe } from '@chakra-ui/react';
import { menuAnatomy } from '@chakra-ui/react/anatomy';
export const menu = defineSlotRecipe({
    slots: menuAnatomy.keys(),
    base: {},
    variants: {
        variant: {
            subtle: {
                item: {
                    cursor: 'pointer',
                    _highlighted: {
                        bg: 'bg.muted',
                    },
                },
            },
        },
    },
    compoundVariants: [],
    defaultVariants: {},
});
