import { HStack, Text } from '@chakra-ui/react';
import { ChevronLeft, Close } from '@cronos-app/icons';
import ConfirmDepositModalView from '@ui/components/Molecules/ConfirmDepositModalView';
import CurrentVaultModalView from '@ui/components/Molecules/CurrentVaultModalView';
import DepositInputModalView from '@ui/components/Molecules/DepositInputModalView';
import MultipleWithdrawModalView from '@ui/components/Molecules/MultipleWithdrawModalView';
import { useCurrentVault } from '@ui/hooks/stores/useCurrentVault';
import { useStakeModal } from '@ui/hooks/stores/useStakeModalView';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import {
  DrawerBackdrop,
  DrawerCloseTrigger,
  DrawerContent,
  DrawerHeader,
  DrawerRoot,
} from '../Drawer';
import { HeaderButton } from './HeaderButton';

const StakeModal = () => {
  const isOpen = useStakeModal((s) => s.isOpen);
  const closeModal = useStakeModal((s) => s.closeModal);
  const view = useStakeModal((s) => s.view);
  const setView = useStakeModal((s) => s.setView);
  const backViewMap = useStakeModal((s) => s.backViewMap);
  const currentVault = useCurrentVault((s) => s.currentVault);
  const t = useTranslations();
  const currentBackView = useMemo(() => {
    return backViewMap[view];
  }, [view, backViewMap]);

  const title = useMemo(() => {
    if (view === 'withdraw') {
      return t('common.WITHDRAW');
    }
    return `${currentVault?.lockPeriodTitle} ${t('common.Vault')}`;
  }, [currentVault?.lockPeriodTitle, t, view]);

  return (
    <DrawerRoot
      size={{ base: 'full', lg: 'md' }}
      open={isOpen}
      preventScroll
      onOpenChange={() => {
        closeModal();
      }}
      placement="end"
    >
      <DrawerBackdrop />
      <DrawerContent
        background={
          view === 'withdraw'
            ? '#CB8AFF'
            : 'linear-gradient(180deg, #080314 0%, #2A0663 25.49%, #2A0663 76%, #080314 100%), linear-gradient(180deg, #080314 0%, #2A0663 100%), radial-gradient(774.32% 178.16% at 50% 108.55%, #000 5%, #0A62AA 37.14%, #000 66.29%), #321B54'
        }
      >
        <DrawerHeader>
          <HStack alignItems="center">
            {currentBackView && (
              <HeaderButton
                isWithdraw={view === 'withdraw'}
                onClick={() => setView(currentBackView)}
              >
                <ChevronLeft />
              </HeaderButton>
            )}
            <Text
              fontSize="2xl"
              fontWeight="bold"
              mx="auto"
              fontFamily="luckiestGuy"
              color={view === 'withdraw' ? 'cPurple.500' : 'white'}
            >
              {title}
            </Text>
            <DrawerCloseTrigger>
              <HeaderButton
                _icon={{ boxSize: 6 }}
                isWithdraw={view === 'withdraw'}
              >
                <Close />
              </HeaderButton>
            </DrawerCloseTrigger>
          </HStack>
        </DrawerHeader>
        {view === 'deposit' && <DepositInputModalView />}
        {view === 'confirmDeposit' && <ConfirmDepositModalView />}
        {view === 'currentVault' && <CurrentVaultModalView />}
        {view === 'withdraw' && <MultipleWithdrawModalView />}
      </DrawerContent>
    </DrawerRoot>
  );
};

export default StakeModal;
