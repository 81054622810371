import { Dialog, Text } from '@chakra-ui/react';
import { currentWallet } from '@cronos-app/wallet';
import { useTranslations } from 'next-intl';

import WhiteButton from '../../WhiteButton';
import { useAccountModal } from '../useAccountModal';
import { MyBalance } from './MyBalance';

export const DefaultContent = () => {
  const t = useTranslations();
  const closeModal = useAccountModal((s) => s.closeModal);

  return (
    <>
      <Dialog.Header bg="#42246D">
        <Text textStyle="h4" color="white">
          {t('common.Your wallet')}
        </Text>
      </Dialog.Header>
      <Dialog.Body
        className="no-scrollbar"
        bg="#42246D"
        pt={{ base: 3, lg: 6 }}
        pb={{ base: 6, lg: 10 }}
      >
        <MyBalance />
      </Dialog.Body>
      <Dialog.Footer bottom="0" bg="#42246D">
        <WhiteButton
          mx="auto"
          w="80%"
          onClick={async () => {
            await currentWallet.disconnect();
            closeModal();
          }}
        >
          {t('common.Disconnect wallet')}
        </WhiteButton>
      </Dialog.Footer>
    </>
  );
};
