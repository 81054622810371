import { currentWallet, useDesiredChain } from '@cronos-app/wallet';
import { BigNumber } from '@ethersproject/bignumber';
import { useQuery } from '@tanstack/react-query';
import { BARA_DECIMALS } from '@ui/config/sc';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';

export const getUseBaraBalanceQueryKey = (account: string | undefined) => [
  'Bara.Balance',
  account,
];

export const useBaraBalance = () => {
  const desiredChainId = useDesiredChain();
  const account = currentWallet.useAccount();

  return useQuery(
    getUseBaraBalanceQueryKey(account),
    async () => {
      const sdk = getSDK(desiredChainId);
      const bara = sdk.getBARA();

      const balance = await bara.balanceOf(account ?? '');

      return {
        totalNumber: BigNumber.from(balance),
        totalAmount: new Fraction(balance).shr(BARA_DECIMALS),
      };
    },
    {
      enabled: !!account,
    },
  );
};
