import { posthog } from '@cronos-labs/react-posthog';
import { BigNumber } from '@ethersproject/bignumber';
import type { Stake } from '@ui/interfaces/stake.interface';
import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import { isNumber } from 'lodash-es';

import { convertBnToNumber, limitDecimals } from '../formatNumber';

export const captureVaultDeposit = (
  vaultId: string | number,
  amount: string | number,
) => {
  try {
    posthog.capture('Deposit', {
      vaultId: vaultId.toString(),
      amount: isNumber(amount) ? amount : Number(limitDecimals(amount, 2)),
    });
  } catch (error) {
    console.error('Error capturing Deposit event', error);
  }
};

export const captureVaultSingleWithdraw = (
  stake: Stake,
  vault: VaultCardInfo,
) => {
  try {
    posthog.capture('Withdraw', {
      stakeId: stake.stakeId.toString(),
      vaultId: vault.pid.toString(),
      amount: convertBnToNumber(stake.amount),
    });
  } catch (error) {
    console.error('Error capturing Withdraw event', error);
  }
};

export const captureVaultMultipleWithdraw = (
  stakeIds: string[],
  vault: VaultCardInfo,
  amountBn: BigNumber,
) => {
  try {
    posthog.capture('Multiple Withdraw', {
      stakeIds,
      vaultId: vault.pid.toString(),
      amount: convertBnToNumber(amountBn),
    });
  } catch (error) {
    console.error('Error capturing Multiple Withdraw event', error);
  }
};

export const captureVaultUpgrade = (stakes: Stake[], vault: VaultCardInfo) => {
  try {
    const stakeIds: string[] = [];
    const amount = BigNumber.from(0);

    stakes.forEach((stake) => {
      stakeIds.push(stake.stakeId.toString());
      amount.add(stake.amount);
    });

    posthog.capture('Upgrade', {
      stakeIds,
      vaultId: vault.pid.toString(),
      amount: convertBnToNumber(amount),
    });
  } catch (error) {
    console.error('Error capturing Upgrade event', error);
  }
};

export const captureVaultHarvest = (amountBn: BigNumber) => {
  try {
    posthog.capture('Harvest', {
      amount: convertBnToNumber(amountBn),
    });
  } catch (error) {
    console.error('Error capturing Harvest event', error);
  }
};
