import type { FlexProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import type { BigNumber } from '@ethersproject/bignumber';
import type { FormatAmountOptions } from '@ui/utils/formatNumber';
import { formatAmount } from '@ui/utils/formatNumber';
import { forwardRef } from 'react';

export type AmountProps = FlexProps & {
  value: BigNumber | undefined;
  decimals?: number;
  prefix?: string;
  suffix?: string;
  displayDecimals?: number;
  defaultValue?: string;
  kmbFormatThreshold?: FormatAmountOptions['kmbFormatThreshold'];
  ref?: React.Ref<HTMLDivElement>;
};

export const Amount: React.FC<AmountProps> = forwardRef<
  HTMLDivElement,
  AmountProps
>(
  (
    {
      value,
      decimals = 18,
      displayDecimals = 2,
      prefix = '',
      suffix = '',
      kmbFormatThreshold,
      defaultValue,
      ...props
    },
    ref,
  ) => {
    const displayingVal = formatAmount(value, {
      decimals,
      displayDecimals,
      defaultValue,
      kmbFormatThreshold,
    });

    const displayingFullVal = formatAmount(value, {
      decimals,
      displayDecimals,
      defaultValue,
    });

    return (
      <Flex
        {...props}
        ref={ref}
        title={
          displayingVal !== displayingFullVal ? displayingFullVal : undefined
        }
      >
        {value ? (
          <>
            {prefix}
            {displayingVal}
            {suffix}
          </>
        ) : (
          displayingVal
        )}
      </Flex>
    );
  },
);

Amount.displayName = 'Amount';
