import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { USD_DECIMALS } from '@ui/config/sc';
import { flow, isString } from 'lodash-es';

/**
 * @description functions help calculations in BigNumber
 */
type BnCalcTarget = number | string | BigNumber;
type BnCalcMiddleware = (value: BigNumber) => BigNumber;

export const expandInStr = (value: string, digits: number) => {
  const [integer, fraction = ''] = value.split('.');

  let _fraction = fraction;
  const dp = fraction.length;

  if (dp < digits) _fraction = _fraction.padEnd(digits, '0');
  if (dp > digits) {
    _fraction =
      _fraction.substring(0, digits) + '.' + _fraction.substring(digits);
  }
  return integer + _fraction;
};

export const toExpandedBn = (value: BnCalcTarget): BigNumber => {
  if (BigNumber.isBigNumber(value)) return value.mul(USD_DECIMALS);

  let valueStr: string;
  if (isString(value)) valueStr = value;
  else valueStr = value.toString();

  return BigNumber.from(expandInStr(valueStr, USD_DECIMALS));
};

export const shrinkBn = (value: BigNumber) => formatUnits(value, USD_DECIMALS);

export const calcInBn = (value: BnCalcTarget, fn: BnCalcMiddleware) =>
  flow(toExpandedBn, fn, shrinkBn)(value);

export const trimDecimalsInStr = (value: string, digits?: number) => {
  const [integer, fraction = ''] = value.split('.');

  let _fraction = fraction;
  const dp = fraction.length;

  if (!!digits && dp > digits) {
    _fraction = _fraction.substring(0, digits);
  }
  _fraction = _fraction.replace(/0+$/, '');

  if (!_fraction) return integer;
  else return integer + '.' + _fraction;
};

export const expandExpNumber = (value: number): string => {
  const valueStr = value.toString();
  const [number, exponent] = valueStr.split('e');

  const exponentNum = Number(exponent);
  if (isNaN(exponentNum)) return valueStr;

  if (exponentNum < 0) {
    const absExponent = Math.abs(exponentNum);
    return calcInBn(number, (bn) =>
      bn.div(BigNumber.from(10).pow(absExponent)),
    );
  }
  return calcInBn(number, (bn) => bn.pow(exponentNum));
};
