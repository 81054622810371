import { type IntlLanguageConfig } from '@ts-intl/integration';
import type { useTranslations } from 'next-intl';

export const languageConfig: IntlLanguageConfig = {
  languageParamName: 'lang',
  defaultLanguage: 'en',
  otherLanguages: [],
};

export type TSignature = ReturnType<typeof useTranslations<never>>;
