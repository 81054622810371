import { BraveWallet } from '@react-web3-wallet/brave-wallet';
import { DeFiWallet } from '@react-web3-wallet/defiwallet';
import { MetaMask } from '@react-web3-wallet/metamask';
import { createCurrentWallet, createWallet } from '@react-web3-wallet/react';
import { getIsSupportedChainId } from './chainConfig';
import { getConfigByName } from './walletConfigs';
export const metaMask = createWallet(getConfigByName(MetaMask.walletName).connector);
export const defiWallet = createWallet(getConfigByName(DeFiWallet.walletName).connector);
export const braveWallet = createWallet(getConfigByName(BraveWallet.walletName).connector);
const walletMap = {
    [MetaMask.walletName]: metaMask,
    [DeFiWallet.walletName]: defiWallet,
    [BraveWallet.walletName]: braveWallet,
};
export const getWallet = (walletName) => {
    const wallet = walletMap[walletName];
    if (wallet)
        return wallet;
    throw new Error(`unknown wallet ${walletName}`);
};
const _currentWallet = createCurrentWallet([metaMask, defiWallet, braveWallet]);
const { useWalletName, useChainId, useIsConnected } = _currentWallet;
const useWalletConfig = () => {
    return getConfigByName(useWalletName());
};
const useIsConnectedToSupportedChain = () => {
    const chainId = useChainId();
    const isConnected = useIsConnected();
    return isConnected && getIsSupportedChainId(chainId);
};
const useSupportedChainId = () => {
    const { useChainId } = _currentWallet;
    const chainId = useChainId();
    return chainId && getIsSupportedChainId(chainId) ? chainId : undefined;
};
const useIsWrongNetwork = () => {
    const chainId = useChainId();
    const isConnected = useIsConnected();
    return isConnected && !getIsSupportedChainId(chainId);
};
const useIsReadOnly = () => {
    return !useIsConnectedToSupportedChain();
};
export const currentWallet = {
    ..._currentWallet,
    useWalletConfig,
    useIsConnectedToSupportedChain,
    useIsReadOnly,
    useIsWrongNetwork,
    useSupportedChainId,
};
