const desktop = '992px';
export const breakpoints = {
    base: '0px',
    xxs: '480px',
    sm: '576px',
    md: '768px',
    lg: desktop,
    desktop: desktop,
    xl: '1280px',
    '2xl': '1600px',
};
