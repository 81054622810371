import { useMemo } from "react";
import { useDesiredChain, useGlobalContextStore } from "./globalContext";
import { currentWallet } from "./wallets";
const useIsWrongNetwork = () => {
    const chainId = currentWallet.useChainId();
    const isConnected = currentWallet.useIsConnected();
    const desiredChain = useDesiredChain();
    return isConnected && chainId !== desiredChain;
};
const useIsConnectedToSupportedChain = () => {
    const chainId = currentWallet.useChainId();
    const isConnected = currentWallet.useIsConnected();
    const desiredChain = useDesiredChain();
    return isConnected && chainId === desiredChain;
};
const useAccount = () => {
    const accountFromStore = useGlobalContextStore((s) => s.account);
    const accountFromWallet = currentWallet.useAccount();
    return useMemo(() => {
        // enable exclude prod
        if (process.env.APP_ENV !== 'prod' && accountFromStore)
            return accountFromStore;
        return accountFromWallet;
    }, [accountFromStore, accountFromWallet]);
};
const useIsReadOnly = () => {
    return !useIsConnectedToSupportedChain();
};
export const currentWalletWithGlobalContext = {
    ...currentWallet,
    // override
    useIsWrongNetwork,
    useIsConnectedToSupportedChain,
    useAccount,
    useIsReadOnly
};
