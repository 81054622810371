import { create } from 'zustand';

type ViewType = 'deposit' | 'confirmDeposit' | 'withdraw' | 'currentVault';

interface UseStakeModal {
  isOpen: boolean;
  view: ViewType;
  closeModal: () => void;
  openModal: (view: ViewType) => void;
  setView: (view: ViewType) => void;
  backViewMap: Record<ViewType, ViewType | null>;
}

export const useStakeModal = create<UseStakeModal>((set) => ({
  isOpen: false,
  view: 'deposit',
  backViewMap: {
    deposit: null,
    confirmDeposit: 'deposit',
    withdraw: 'currentVault',
    currentVault: null,
  },
  closeModal: () => {
    set({ isOpen: false });
  },
  openModal: (view: ViewType) => {
    set({ isOpen: true, view });
  },
  setView: (view: ViewType) => {
    // TODO: should find a better way to solve the drawer dismiss issue when setView change
    setTimeout(() => set({ view }), 10);
  },
}));
