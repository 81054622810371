import { Box, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface Performance {
  memory?: {
    usedJSHeapSize: number;
    totalJSHeapSize: number;
    jsHeapSizeLimit: number;
  };
}

declare const performance: Performance;

interface MemoryUsage {
  total: number;
  used: number;
  limit: number;
}

const memoryMonitor = (
  report: (usage: MemoryUsage) => void,
  duration: number,
) => {
  if (!performance.memory) return;
  const id = setInterval(() => {
    if (!performance.memory) return;
    report({
      total: performance.memory.totalJSHeapSize / 1024 / 1024,
      used: performance.memory.usedJSHeapSize / 1024 / 1024,
      limit: performance.memory.jsHeapSizeLimit / 1024 / 1024,
    });
  }, duration);
  return () => clearInterval(id);
};

const MEMORY_BREAKPOINTS = {
  SLOW: 0.6,
  MID: 0.4,
};

export const MemoryMonitor = ({ duration = 2000 }: { duration?: number }) => {
  const [{ total, used, limit }, setUsage] = useState<MemoryUsage>({
    total: NaN,
    used: NaN,
    limit: NaN,
  });

  useEffect(() => {
    memoryMonitor(setUsage, duration);
  }, [duration]);

  return (
    <Box
      color={
        used / limit > MEMORY_BREAKPOINTS.SLOW
          ? 'red'
          : used / limit > MEMORY_BREAKPOINTS.MID
            ? 'orange'
            : 'lime'
      }
      transition="all .2s ease"
    >
      <Text>{used.toFixed(2)} U</Text>
      <Text>{total.toFixed(2)} T</Text>
      <Text>{limit.toFixed(2)} L</Text>
    </Box>
  );
};
