import { Box, HStack, VStack } from '@chakra-ui/react';
import DepositUnlockLabel from '@ui/components/Atoms/DepositUnlockLabel';
import SecondaryButton from '@ui/components/Atoms/SecondaryButton';
import { SilverSeparator } from '@ui/components/Atoms/Separator/SilverSeparator';
import { StakeInfoToken } from '@ui/components/Atoms/StakaInfoToken';
import UnlockDate from '@ui/components/Atoms/UnlockDate';
import type { Stake } from '@ui/interfaces/stake.interface';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';

interface StakeInfoRowProps {
  stake: Stake;
  onWithdraw?: () => void;
  isWithdrawLoading?: boolean;
}

const StakeInfoRow: FC<StakeInfoRowProps> = ({
  stake,
  isWithdrawLoading,
  onWithdraw,
}) => {
  const t = useTranslations();

  return (
    <HStack>
      <Box alignSelf="stretch">
        <SilverSeparator orientation="vertical" thickness={0.5} />
      </Box>
      <VStack alignItems="flex-start" position="relative" ml={2}>
        <StakeInfoToken value={stake.amount} />
        <UnlockDate
          unlockTimestamp={stake.unlockTimestamp}
          isInVaultCard={false}
        />
        {stake.isUnlocked && <DepositUnlockLabel isInVaultCard={false} />}
        {stake.isUnlocked && (
          <SecondaryButton loading={isWithdrawLoading} onClick={onWithdraw}>
            {t('common.WITHDRAW')}
          </SecondaryButton>
        )}
      </VStack>
    </HStack>
  );
};

export default StakeInfoRow;
