import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import { useMemo } from 'react';

import useBaraVaultUserInfo from './useBaraVaultUserInfo';

const useBaraVaultUnlockStakes = (currentVault: VaultCardInfo | null) => {
  const { data } = useBaraVaultUserInfo();

  const currentAvailableWithdrawStakes = useMemo(() => {
    if (!currentVault || !data?.poolStakeMap) return [];

    return data.poolStakeMap[currentVault.pid].filter(
      (stake) => stake.isUnlocked,
    );
  }, [currentVault, data?.poolStakeMap]);

  return currentAvailableWithdrawStakes;
};

export default useBaraVaultUnlockStakes;
