import { currentWallet, useDesiredChain } from '@cronos-app/wallet';
import { BigNumber } from '@ethersproject/bignumber';
import { MaxUint256 } from '@ethersproject/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toaster } from '@ui/components/Atoms/Toast';
import { BARA_DECIMALS } from '@ui/config/sc';
import { useCurrentVault } from '@ui/hooks/stores/useCurrentVault';
import { getSDK } from '@ui/utils';
import { Fraction } from 'bi-fraction';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import useBaraVaultUserInfo, {
  getUseBaraVaultUserInfoQueryKey,
} from './useBaraVaultUserInfo';
import { getUseBaraVaultInfoListQueryKey } from './useVaultInfoList';

const useBaraVaultDeposit = () => {
  const desiredChainId = useDesiredChain();
  const sdk = getSDK(desiredChainId);
  const [isLoading, setIsLoading] = useState(false);
  const account = currentWallet.useAccount();
  const { currentVault, inputTokenAmount } = useCurrentVault();
  const provider = currentWallet.useProvider();
  const isWrongNetwork = currentWallet.useIsWrongNetwork();
  const t = useTranslations();
  const queryClient = useQueryClient();
  const { data: userInfoData } = useBaraVaultUserInfo();

  // TODO: add toast for notification
  const data = useMutation(
    ['BaraVault.deposit'],
    async () => {
      const signer = await provider?.getSigner();
      const baraContract = sdk.getBARA(signer);
      const baraVaultContract = sdk.getBaraVault(signer);
      setIsLoading(true);
      try {
        if (account && currentVault && !isWrongNetwork) {
          const inputTokenInt = new Fraction(inputTokenAmount).shl(
            BARA_DECIMALS,
          ).quotient;

          const allowance = await baraContract.allowance(
            account,
            sdk.getContractAddress('BaraVault'),
          );

          const allowanceWithBigNumber = BigNumber.from(allowance);

          if (allowanceWithBigNumber.lt(inputTokenInt)) {
            const approveRet = await baraContract.approve(
              sdk.getContractAddress('BaraVault'),
              MaxUint256.toString(),
            );

            toaster.create({
              title: t('common.Approving token spending'),
              type: 'loading',
              id: approveRet.hash,
              meta: { txHash: approveRet.hash },
            });

            await approveRet.wait();

            toaster.dismiss(approveRet.hash);

            toaster.success({
              title: t('common.Approval_Success_Toast_Title'),
              description: t(
                'common.Spending your tokens was approved successfully',
              ),
            });
          }

          const tx = await baraVaultContract.deposit(
            currentVault.pid,
            inputTokenInt,
          );

          toaster.create({
            title: t('common.Depositing token in vault'),
            type: 'loading',
            id: tx.hash,
            meta: { txHash: tx.hash },
          });

          const receipt = await tx.wait();

          toaster.dismiss(tx.hash);

          toaster.success({
            title: t('common.Transaction Completed'),
            description: t('common.{amount} BARA have been staked', {
              amount: inputTokenAmount,
            }),
            meta: { txHash: tx.hash },
          });

          return { receipt };
        }
      } catch (error) {
        toaster.error({
          title: t('common.Transaction Failed'),
        });
        setIsLoading(false);
      }
    },
    {
      onError: () => {
        setIsLoading(false);
      },
      onSuccess: () => {
        setIsLoading(false);
        queryClient.invalidateQueries(
          getUseBaraVaultInfoListQueryKey(userInfoData),
        );
        queryClient.invalidateQueries(getUseBaraVaultUserInfoQueryKey(account));
      },
    },
  );

  return {
    ...data,
    isLoading,
  };
};

export default useBaraVaultDeposit;
