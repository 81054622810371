/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { currentWallet } from '@cronos-app/wallet';
import { BigNumber } from '@ethersproject/bignumber';
import type { QueryKey } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useBaraVaultContract } from '@ui/contexts/BaraVaultContractContext';
import type { Stake } from '@ui/interfaces/stake.interface';

type UserStakeMap = Record<number, Stake[]>;

export interface BaraVaultUserInfo {
  totalAmount: BigNumber;
  totalWeightedAmount: BigNumber;
  rewardDebt: BigNumber;
  poolStakeMap: UserStakeMap;
  totalClaimed: BigNumber;
  poolTotalStakeMap: Record<number, BigNumber>;
}

export const getUseBaraVaultUserInfoQueryKey = (
  account: string | undefined,
): QueryKey => ['BaraVault.UserInfo', account];

const useBaraVaultUserInfo = () => {
  const { baraVaultContract } = useBaraVaultContract();
  const account = currentWallet.useAccount();

  return useQuery<BaraVaultUserInfo>(
    getUseBaraVaultUserInfoQueryKey(account),
    async () => {
      if (!baraVaultContract || !account) {
        return {
          totalAmount: BigNumber.from(0),
          totalWeightedAmount: BigNumber.from(0),
          rewardDebt: BigNumber.from(0),
          totalClaimed: BigNumber.from(0),
          poolTotalStakeMap: {
            0: BigNumber.from(0),
            1: BigNumber.from(0),
            2: BigNumber.from(0),
          },
          poolStakeMap: {
            0: [],
            1: [],
            2: [],
          },
        } as unknown as BaraVaultUserInfo;
      }
      const userInfo = await baraVaultContract.getUserInfo(account);
      return {
        totalAmount: BigNumber.from(userInfo[0]),
        totalWeightedAmount: BigNumber.from(userInfo[1]),
        rewardDebt: BigNumber.from(userInfo[3]),
        totalClaimed: BigNumber.from(userInfo[2]),
        poolTotalStakeMap: userInfo[4].reduce<Record<number, BigNumber>>(
          (acc, stake) => {
            const poolId = BigNumber.from(stake.poolId);
            if (!stake.active) {
              return acc;
            }
            const totalAmount = acc[poolId.toNumber()]
              ? acc[poolId.toNumber()].add(BigNumber.from(stake.amount))
              : BigNumber.from(stake.amount);
            return {
              ...acc,
              [poolId.toNumber()]: totalAmount,
            };
          },
          {
            0: BigNumber.from(0),
            1: BigNumber.from(0),
            2: BigNumber.from(0),
          },
        ),
        poolStakeMap: userInfo[4].reduce<UserStakeMap>(
          (acc, stake, idx) => {
            const poolId = BigNumber.from(stake.poolId);
            const stakes = acc[poolId.toNumber()] ?? [];
            const amount = BigNumber.from(stake.amount);
            const now = Math.round(new Date().getTime() / 1000);

            if (!stake.active) {
              return acc;
            }

            return {
              ...acc,
              [poolId.toNumber()]: [
                ...stakes,
                {
                  stakeTimestamp: BigNumber.from(stake.stakeTimestamp),
                  unlockTimestamp: BigNumber.from(stake.unlockTimestamp),
                  amount,
                  weightedAmount: BigNumber.from(stake.weightedAmount),
                  isUnlocked: BigNumber.from(stake.unlockTimestamp).lte(now),
                  active: stake.active,
                  stakeId: BigNumber.from(idx),
                } as Stake,
              ],
            };
          },
          {
            0: [],
            1: [],
            2: [],
          },
        ),
      } as BaraVaultUserInfo;
    },
    { enabled: !!baraVaultContract && !!account },
  );
};

export default useBaraVaultUserInfo;
