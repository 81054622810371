import { createSystem, defaultConfig } from '@chakra-ui/react';
import { chakraConfig } from '@cronos-app/chakra';

import { fontChakraConfig } from './fontChakraConfig';

const chakraSystem = createSystem(
  defaultConfig,
  chakraConfig,
  fontChakraConfig,
);

export default chakraSystem;
