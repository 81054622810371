import { Box, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { CapybaraToken } from '@cronos-app/icons';
import type { TwoColumnInfoRowProps } from '@ui/components/Atoms/TwoColumnInfoRow';
import { TwoColumnInfoRow } from '@ui/components/Atoms/TwoColumnInfoRow';
import { useCurrentVault } from '@ui/hooks/stores/useCurrentVault';
import { useStakeModal } from '@ui/hooks/stores/useStakeModalView';
import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import { estimateLockEndDate } from '@ui/utils/date';
import { formatNumber } from '@ui/utils/formatNumber';
import { useTranslations } from 'next-intl';
import { type FC, useMemo } from 'react';

interface StakeDetailProps {
  vault: VaultCardInfo;
}

const StakeDetail: FC<StakeDetailProps> = ({ vault }) => {
  const t = useTranslations();
  const view = useStakeModal((s) => s.view);
  const inputTokenAmount = useCurrentVault((s) => s.inputTokenAmount);

  const rows = useMemo<TwoColumnInfoRowProps[]>(() => {
    const rows: TwoColumnInfoRowProps[] = [];

    if (view === 'confirmDeposit' && inputTokenAmount) {
      rows.push({
        title: t('common.Deposit amount'),
        value: (
          <HStack>
            <Icon boxSize={{ base: 5, lg: 7 }} mb={1}>
              <CapybaraToken />
            </Icon>
            <Text textStyle={{ base: 'h5', lg: 'h4' }}>
              {formatNumber(Number(inputTokenAmount))}
            </Text>
          </HStack>
        ),
      });
    }

    return rows.concat([
      {
        title: t('common.Type of vault'),
        value: `${vault.lockPeriodTitle} ${t('common.Vault')}`,
      },
      {
        title: t('common.APR'),
        // eslint-disable-next-line @ts-intl/ts-intl/no-raw-text
        value: `${formatNumber((vault.apr ?? 0) * 100)} %`,
      },
      {
        title: t('common.Multiplier'),
        value: `${vault.multiplier}x`,
      },
      {
        title: t('common.Unlocked date'),
        value: estimateLockEndDate(vault.lockPeriod),
      },
    ]);
  }, [
    inputTokenAmount,
    t,
    vault.apr,
    vault.lockPeriod,
    vault.lockPeriodTitle,
    vault.multiplier,
    view,
  ]);

  return (
    <Box mt={6} w="full">
      <Text color="#C377FF" textStyle="subTitle2" fontSize={18} mb={3}>
        {t('common.Staking Details')}
      </Text>
      <Flex gap={2} flexDirection="column" pt={3}>
        {rows.map((row) => (
          <TwoColumnInfoRow {...row} key={row.title} />
        ))}
      </Flex>
    </Box>
  );
};

export default StakeDetail;
