/**
 * link theme
 * @see https://github.com/chakra-ui/chakra-ui/blob/%40chakra-ui/react%403.0.0/packages/react/src/theme/recipes/link.ts
 */
import { defineRecipe } from '@chakra-ui/react';
export const link = defineRecipe({
    base: {},
    variants: {
        variant: {
            plain: {
                _hover: {
                    textDecoration: 'none',
                },
            },
        },
    },
    compoundVariants: [],
    defaultVariants: {},
});
