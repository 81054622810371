import type { FlexProps } from '@chakra-ui/react';
import { Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { CapybaraToken } from '@cronos-app/icons';
import { Num } from '@ui/components/Num';
import { useBaraBalance } from '@ui/hooks/contracts/useBaraBalance';
import { useTranslations } from 'next-intl';

export type MyBalanceProps = FlexProps;
export const MyBalance = (props: MyBalanceProps) => {
  const t = useTranslations();
  const { data } = useBaraBalance();
  return (
    <Flex align="center" justify="space-between" {...props}>
      <Text color="white" fontSize={{ base: 18, lg: 20 }}>
        {t('common.{token} Balance', { token: '$BARA' })}
      </Text>
      <VStack align="flex-end">
        <HStack>
          <Icon boxSize={5} mb={1}>
            <CapybaraToken />
          </Icon>
          <Num color="white" textStyle="h5" value={data?.totalAmount} />
        </HStack>
      </VStack>
    </Flex>
  );
};
