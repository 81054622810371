import { BigNumber } from '@ethersproject/bignumber';
import { USD_DECIMALS } from '@ui/config/sc';
import type { InfoApiTokenSlug } from '@ui/config/urls';
import { useConvertTokenValueBnToUsdPriceBn } from '@ui/hooks/api/useUsdPrice';
import { forwardRef } from 'react';

import type { AmountProps } from '../Amount';
import { Amount } from '../Amount';

export type AmountUsdProps = Omit<AmountProps, 'decimals'> & {
  ref?: React.Ref<HTMLDivElement>;
};

export const AmountUsd: React.FC<AmountUsdProps> = forwardRef<
  HTMLDivElement,
  AmountUsdProps
>(({ prefix = '$', ...props }, ref) => {
  return (
    <Amount {...props} decimals={USD_DECIMALS} prefix={prefix} ref={ref} />
  );
});

AmountUsd.displayName = 'AmountUsd';

export type AmountTokenUsdProps = Omit<AmountProps, 'decimals'> & {
  ref?: React.Ref<HTMLDivElement>;
  tokenSlug: InfoApiTokenSlug;
};

export const AmountTokenUsd: React.FC<AmountTokenUsdProps> = forwardRef<
  HTMLDivElement,
  AmountTokenUsdProps
>(({ prefix = '$', value = BigNumber.from(0), tokenSlug, ...props }, ref) => {
  const { data } = useConvertTokenValueBnToUsdPriceBn(value, tokenSlug);

  return (
    <Amount
      {...props}
      ref={ref}
      prefix={prefix}
      value={data}
      decimals={USD_DECIMALS}
    />
  );
});

AmountTokenUsd.displayName = 'AmountTokenUsd';
