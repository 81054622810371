import { defineTextStyles } from '@chakra-ui/react';
/**
 * need to add type annotation
 * @see https://github.com/microsoft/TypeScript/issues/47663#issuecomment-1519138189
 */
export const textStyles = defineTextStyles({
    // display headline
    d1: {
        value: {
            fontSize: { base: 54, lg: 90 },
            fontWeight: 'semibold',
            lineHeight: { base: '64px', lg: '108px' },
        },
    },
    d2: {
        value: {
            fontSize: { base: 32, lg: 54 },
            fontWeight: 'semibold',
            lineHeight: { base: '40px', lg: '64px' },
        },
    },
    // page headline
    h1: {
        value: {
            fontSize: { base: 28, lg: 48 },
            fontWeight: 'semibold',
            lineHeight: { base: '32px', lg: '55px' },
        },
    },
    h2: {
        value: {
            fontSize: { base: 22, lg: 40 },
            fontWeight: 'semibold',
            lineHeight: { base: '26px', lg: '48px' },
        },
    },
    // section headline
    h3: {
        value: {
            fontSize: { base: 18, lg: 28 },
            fontWeight: 'semibold',
            lineHeight: { base: '24px', lg: '32px' },
        },
    },
    // dialog headings, card headings and section headings
    h4: {
        value: {
            fontSize: { base: 22 },
            fontWeight: { base: 'bold', lg: 'semibold' },
            lineHeight: { base: '30px' },
        },
    },
    h5: {
        value: {
            fontSize: { base: 16 },
            fontWeight: { base: 'bold', lg: 'semibold' },
            lineHeight: { base: '24px' },
        },
    },
    // subheading under headlines
    subhead1: {
        value: {
            fontSize: { base: 18, lg: 32 },
            fontWeight: 'normal',
            lineHeight: { base: '24px', lg: '40px' },
        },
    },
    subhead2: {
        value: {
            fontSize: { base: 16, lg: 24 },
            fontWeight: 'normal',
            lineHeight: { base: '24px', lg: '32px' },
        },
    },
    // body text (e.g. introduction)
    body1: {
        value: {
            fontSize: { base: '14px', lg: '22px' },
            fontWeight: 'normal',
            lineHeight: { base: '20px', lg: '26px' },
        },
    },
    body1Emphasis: {
        value: {
            fontSize: { base: 14, lg: 16 },
            fontWeight: 'semibold',
            lineHeight: { base: '20px', lg: '24px' },
        },
    },
    // captions and supplementary text, T&C
    caption1: {
        value: {
            fontSize: { base: 12, lg: 14 },
            lineHeight: { base: '16px', lg: '20px' },
        },
    },
    // microcopy (e.g. avatar initials)
    caption2: {
        value: {
            fontSize: { base: 10, lg: 12 },
            fontWeight: 'light',
            lineHeight: { base: '14px', lg: '16px' },
        },
    },
    caption3: {
        value: {
            fontSize: { base: '18px' },
            lineHeight: { base: '22px' },
        },
    },
    caption4: {
        value: {
            fontSize: { base: '14px' },
            lineHeight: { base: '18px' },
        },
    },
    nav: {
        value: {
            fontSize: { base: '24px' },
            fontWeight: 500,
            lineHeight: { base: '28px' },
        },
    },
    footer: {
        value: {
            fontSize: { base: '16px', lg: '20px' },
            fontWeight: 400,
            lineHeight: { base: '20px', lg: '24px' },
        },
    },
    subTitle2: {
        value: {
            fontSize: { base: '19px' },
            fontWeight: 400,
            lineHeight: { base: '19px' },
        },
    },
});
