import { ChainId } from '../types';
const ADDRESS_BY_CHAIN = {
    [ChainId.CRONOS_MAINNET]: {
        WCRO: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
        CronosId: '0x7F4C61116729d5b27E5f180062Fdfbf32E9283E5',
        BaraVault: '0xe4960C2cB3B5edDf32A712DbA733Ef784571CfAb',
        BARA: '0xF24409D155965ca87c45aD5BC084aD8aD3Be4f39',
    },
    [ChainId.CRONOS_TESTNET]: {
        WCRO: '0x6a3173618859C7cd40fAF6921b5E9eB6A76f1fD4',
        CronosId: '0x16a23bFBcE9c53998c90201629E4cDB40B81B127',
        BaraVault: '0x44Ea326c6533bC708395ffE88675F2feF6036192',
        BARA: '0xBd474429Ef79FA377d06Fae0f9Dd332B30D55D7e',
    },
};
export const getContractAddress = (chainId, contractName) => {
    return ADDRESS_BY_CHAIN[chainId][contractName];
};
export const getEnsAddress = (chainId) => {
    switch (chainId) {
        case ChainId.CRONOS_MAINNET:
        case ChainId.CRONOS_TESTNET:
            return getContractAddress(chainId, 'CronosId');
        default:
            throw new Error('unreachable');
    }
};
