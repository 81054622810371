import type { InputProps } from '@chakra-ui/react';
import { Flex } from '@chakra-ui/react';
import { CustomTokenInput } from '@ui/components/Atoms/CustomTokenInput';
import { InputStepper } from '@ui/components/Atoms/InputStepper';
import { isNil } from 'lodash-es';

export interface AmountInputProps {
  max?: InputProps['max'];
  value?: string;
  placeholder?: string;
  maxDecimals?: number;
  usdValue?: number;
  onChange?: (value: string) => void;
}

export const AmountInput = ({
  max,
  value,
  placeholder,
  maxDecimals,
  onChange,
}: AmountInputProps) => {
  return (
    <Flex flexDir="column" gap={4} w="full">
      <CustomTokenInput
        max={max}
        value={value}
        decimals={maxDecimals}
        placeholder={placeholder}
        onValueChange={onChange}
      />
      {!isNil(max) && (
        <InputStepper max={max} onChange={onChange} maxDecimals={maxDecimals} />
      )}
    </Flex>
  );
};
