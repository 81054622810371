import { formatNumber } from './formatNumber';

export const formatApr = (
  value?: number | null,
  type: 'APR' | 'APY' = 'APR',
): string => (value ? formatNumber(value * 100) + '% ' + type : '-- ' + type);

const TIMESTAMP_PER_YEAR = 31536000;

export const estimateReward = (
  deposit: number | undefined,
  lockPeriod?: number | null,
  apr?: number | null,
): number => {
  if (apr === null || apr === undefined || !deposit || !lockPeriod) return 0;
  return deposit * apr * (lockPeriod / TIMESTAMP_PER_YEAR);
};
