import type { InputProps } from '@chakra-ui/react';
import { Input } from '@chakra-ui/react';
import { isNil } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';

const SpecialKeys = [
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
  'Escape',
];

export interface CustomNumberInputProps
  extends Omit<InputProps, 'value' | 'placeholder' | 'onChange'> {
  max?: InputProps['max'];
  value?: string;
  placeholder?: string;
  decimals?: number;
  onBlur?: () => void;
  onValueChange?: (value: string) => void;
}

/**
 * @description
 * Normal number input component cannot handle long decimals and large value.
 * e.g. Long decimals: 0.1234567890123456789 -> 0.12345678901234567
 * e.g. Large value: 12345678901234567890 -> 1.2345678901234568e+19
 * This component is designed to handle these cases.
 */
export const CustomNumberInput = ({
  max,
  value,
  placeholder,
  decimals = 18,
  onBlur,
  onValueChange,
  ...props
}: CustomNumberInputProps) => {
  // Internal value for display (Edge case: 123. -> 123)
  const [_value, _setValue] = useState(value);

  const filterOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isNumber = new RegExp(/^[0-9]$/).test(e.key);
    const isFirstDot = e.key === '.' && !!_value && !_value.includes('.');

    const isSpecialKey = SpecialKeys.includes(e.key);
    const isCmdAction = e.metaKey && ['a', 'c', 'x'].includes(e.key);

    if (isNumber || isSpecialKey || isFirstDot || isCmdAction) return;
    else e.preventDefault();
  };

  const setValueOnChange = (newValue: string) => {
    const isTrailingDot = newValue.endsWith('.');

    _setValue(newValue);
    if (!isTrailingDot) onValueChange?.(newValue);
  };

  const normalizeInputValue = useCallback(
    (newValue: string) => {
      let output = newValue;

      const isOverMax = !isNil(max) && checkIsLarger(newValue, max);
      if (isOverMax) output = max.toString();

      const isHeadingDot = output.startsWith('.');
      if (isHeadingDot) output = `0${output}`;

      const isHeadingZero = output.startsWith('0') && output.length > 1;
      if (isHeadingZero) output = output.replace(/^0+(?=\d)/, '');

      const isExceedDecimals =
        output.split('.').length > 1 && output.split('.')[1].length > decimals;
      if (isExceedDecimals) {
        const [integer, decimal] = output.split('.');
        output = integer + '.' + decimal.slice(0, decimals);
      }

      return {
        value: output,
        isOverMax,
        isHeadingDot,
        isHeadingZero,
        isExceedDecimals,
      };
    },
    [decimals, max],
  );

  const filterOnValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const { value: newValue, isExceedDecimals } =
      normalizeInputValue(inputValue);

    if (isExceedDecimals) e.preventDefault();
    else setValueOnChange(newValue);
  };

  const formatOnBlur = () => {
    onBlur?.();
    let output = _value;
    if (!isNil(_value) && _value.endsWith('.')) {
      output = _value.slice(0, -1);
    }
    _setValue(output);
  };

  useEffect(() => {
    if (isNil(value)) return;
    _setValue(normalizeInputValue(value).value);
  }, [value, _setValue, normalizeInputValue]);

  return (
    <Input
      border="none"
      _focus={{ outline: 'none' }}
      value={_value}
      placeholder={placeholder}
      onBlur={formatOnBlur}
      onKeyDown={filterOnKeyDown}
      onChange={filterOnValueChange}
      {...props}
    />
  );
};

type Value = number | string | undefined;

function checkIsLarger(a: Value, b: Value): boolean {
  const _a = typeof a === 'string' ? parseFloat(a) : a;
  const _b = typeof b === 'string' ? parseFloat(b) : b;
  if (isNil(_a) || isNil(_b)) return false;
  else return _a > _b;
}
