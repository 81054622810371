import { useEffect, useState } from 'react';

import { NoSSR } from './NoSSR';

export interface LazyComponentProps {
  children: React.ReactNode;
  isOpen: boolean;
}

export const LazyComponent: React.FC<LazyComponentProps> = ({
  isOpen,
  children,
}) => {
  // delay executing the child component code till the child component is `opened`
  const [isShow, setIsShow] = useState(isOpen);

  useEffect(() => {
    if (isOpen && !isShow) setIsShow(true);
  }, [isOpen, isShow]);

  return <NoSSR>{isShow && children}</NoSSR>;
};
