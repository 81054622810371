import { Button, Dialog, Link, Text } from '@chakra-ui/react';

import { useAccountModal } from '../useAccountModal';

export const DisclaimerContent = () => {
  const disclaimerUrl = useAccountModal((s) => s.disclaimerUrl);
  const openDefault = useAccountModal((s) => s.openDefault);

  return (
    <>
      <Dialog.Header>
        <Text textStyle="h4">Leaving Cronos App</Text>
      </Dialog.Header>
      <Dialog.Body className="no-scrollbar">
        <Text color="#626163">
          You are about to access a third party platform owned and operated by
          an independent party over which Cronos App has no control (“Third
          Party Platform”). Your continuation to the Third Party Platform and
          access to the services offered thereof (“Third Party Services”) will
          be at your own risk. Any use of the Third Party Platform and Third
          Party Services, and any information you provide, will be subject to
          and governed by the policies and terms of the relevant third parties,
          and may be subject to separate fees charged by those third parties.
        </Text>
      </Dialog.Body>
      <Dialog.Footer flexDirection="column" justifyContent="center">
        <Button asChild>
          <Link w="full" href={disclaimerUrl} target="_blank" color="white">
            Agree & Continue
          </Link>
        </Button>
        <Link onClick={openDefault} my="10px">
          Dismiss
        </Link>
      </Dialog.Footer>
    </>
  );
};
