import { currentWallet } from '@cronos-app/wallet';
import { useMount } from 'react-use';

export const useInitializeWallet = () => {
  useMount(() => {
    if (process.env.IS_DISABLED_STAKING !== 'true') {
      currentWallet.autoConnect();
    }
  });
};
