/* eslint-disable @ts-intl/ts-intl/no-dynamic-keys */
import { HStack, Icon, Text } from '@chakra-ui/react';
import { CheckCircle, Lock } from '@cronos-app/icons';
import type { BigNumber } from '@ethersproject/bignumber';
import { formatUnlockTimestamp } from '@ui/utils/date';
import { useTranslations } from 'next-intl';
import type { FC } from 'react';

interface UnlockDateProps {
  unlockTimestamp: BigNumber;
  isInVaultCard?: boolean;
  isInStakeModal?: boolean;
}

const UnlockDate: FC<UnlockDateProps> = ({
  unlockTimestamp,
  isInVaultCard,
  isInStakeModal,
}) => {
  const t = useTranslations();
  const unlockDate = formatUnlockTimestamp(unlockTimestamp.toNumber());

  if (isInStakeModal) {
    return (
      <HStack w="full" align="center" gap={1} fontFamily="luckiestGuy">
        <Icon boxSize={6}>{unlockDate ? <Lock /> : <CheckCircle />}</Icon>
        <Text textStyle="h5" pt={1}>
          {unlockDate ?? t('common.Deposit Unlocked')}
        </Text>
      </HStack>
    );
  }

  if (!unlockDate) {
    return null;
  }

  if (isInVaultCard) {
    return (
      <HStack
        alignItems="center"
        background="#B4B4B4"
        w="full"
        py={2}
        px={{ base: 2, lg: 4 }}
        fontFamily="luckiestGuy"
      >
        <Icon color="black" boxSize={{ base: 5, lg: 6 }}>
          <Lock />
        </Icon>
        <Text color="#black" fontSize={{ base: 12, lg: 16 }} mt={1}>
          {unlockDate}
        </Text>
      </HStack>
    );
  }

  return (
    <HStack alignItems="center" fontFamily="luckiestGuy">
      <Icon color="#D7D7D7">
        <Lock />
      </Icon>
      <Text color="#D7D7D7">{unlockDate}</Text>
    </HStack>
  );
};

export default UnlockDate;
