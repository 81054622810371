import { Flex, HStack, Text } from '@chakra-ui/react';
import { AmountTokenUsd } from '@ui/components/Atoms/AmountUsd';
import type { RowHighlightSelectProps } from '@ui/components/Atoms/RowHighlightSelect';
import { RowHighlightSelect } from '@ui/components/Atoms/RowHighlightSelect';
import { BARA_DECIMALS } from '@ui/config/sc';
import { InfoApiTokenSlug } from '@ui/config/urls';
import type { Stake } from '@ui/interfaces/stake.interface';
import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import { formatNumber, safeParseUnits } from '@ui/utils/formatNumber';
import { useTranslations } from 'next-intl';

import { useUpgradeRewards } from './useUpgradeRewards';
import { VaultInfoTag } from './VaultInfoTag';

interface VaultSelectionProps extends RowHighlightSelectProps {
  vault: VaultCardInfo;
  stakes: Stake[];
}

export const VaultSelection = ({
  vault,
  stakes,
  ...props
}: VaultSelectionProps) => {
  const t = useTranslations();
  const { apr, lockPeriodTitle } = vault;
  const { totalReward } = useUpgradeRewards(stakes, vault);

  return (
    <RowHighlightSelect {...props}>
      <Flex flexDir="column">
        <HStack
          align="center"
          flexFlow="wrap"
          gapY={0}
          fontFamily="luckiestGuy"
        >
          <Text textStyle="h6" fontSize={20} textTransform="uppercase">
            {lockPeriodTitle
              ? t('common.{name} VAULT', { name: lockPeriodTitle })
              : t('common.VAULT')}
          </Text>
          {apr && (
            <VaultInfoTag color="white" loading={false} mb={1.5}>
              <Text>{formatNumber(apr * 100)} % APR</Text>
            </VaultInfoTag>
          )}
        </HStack>
        <Text textStyle="subtitle-1">{t('common.ESTIMATED REWARDS')}:</Text>
        <AmountTokenUsd
          tokenSlug={InfoApiTokenSlug.Bara}
          value={safeParseUnits(totalReward, BARA_DECIMALS)}
          textStyle="h4"
          fontSize={{ base: 18, lg: 20 }}
        />
      </Flex>
    </RowHighlightSelect>
  );
};
