import type { TextProps} from '@chakra-ui/react';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { CheckCircle, Lock } from '@cronos-app/icons';
import { formatUnlockTimestamp } from '@ui/utils/date';
import { isPast } from 'date-fns';
import { useTranslations } from 'next-intl';

interface UpgradeStakeDetailsProps {
  unlockTimestamp: number;
  textProps?: TextProps;
}

export const LockTimeChange = ({
  unlockTimestamp,
  textProps,
}: UpgradeStakeDetailsProps) => {
  const t = useTranslations();

  if (isPast(unlockTimestamp * 1000))
    return (
      <HStack align="center" gap={1}>
        <Icon boxSize={5}>
          <CheckCircle />
        </Icon>
        <Text {...textProps}>{t('common.Deposit Unlocked')}</Text>
      </HStack>
    );

  return (
    <HStack alignItems="center" gap={1}>
      <Icon boxSize={5}>
        <Lock />
      </Icon>
      <Text {...textProps}>{formatUnlockTimestamp(unlockTimestamp)}</Text>
    </HStack>
  );
};
