import {
  Box,
  type ButtonProps as ChakraButtonProps,
  Text,
} from '@chakra-ui/react';
import Button from '@ui/components/Atoms/Button';
import { forwardRef } from 'react';

interface ButtonLoadingProps {
  loading?: boolean;
  loadingText?: React.ReactNode;
}

export interface ButtonProps extends ChakraButtonProps, ButtonLoadingProps {}

const SecondaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  function UIButton(props, ref) {
    const { children, ...restProps } = props;

    return (
      <Box
        display="inline-block"
        position="relative"
        p="1px" // The size of the border
        background="linear-gradient(90deg, #FBE85B 0%, #FF5E1A 100%)"
        borderRadius="100px"
      >
        <Button
          ref={ref}
          position="relative"
          bg="cPurple.500" // Background of the button itself
          borderRadius="inherit"
          zIndex={1}
          color=""
          px="40px"
          py={2}
          unstyled
          _disabled={{ opacity: 0.8 }}
          {...restProps}
        >
          <Text
            background="linear-gradient(90deg, #FBE85B 0%, #FF5E1A 100%)"
            backgroundClip="text"
            WebkitTextFillColor="transparent"
            fontFamily="luckiestGuy"
            fontSize="16px"
            lineHeight="20px"
          >
            {children}
          </Text>
        </Button>
      </Box>
    );
  },
);

export default SecondaryButton;
