import {
  Button,
  DrawerBody,
  DrawerFooter,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useUpgradeVault } from '@ui/contexts/UpgradeVaultContext';
import useBaraVaultUserInfo from '@ui/hooks/contracts/BaraVault/useBaraVaultUserInfo';
import { useCurrentVault } from '@ui/hooks/stores/useCurrentVault';
import { useUpgradeModal } from '@ui/hooks/stores/useUpgradeModalView';
import type { Stake } from '@ui/interfaces/stake.interface';
import { isNil } from 'lodash-es';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { DepositSelection } from './DepositSelection';

export const SelectUpgradeDepositModalView = () => {
  const t = useTranslations();

  const { data } = useBaraVaultUserInfo();
  const { setView } = useUpgradeModal();
  const { stakes, setStakes } = useUpgradeVault();
  const currentVault = useCurrentVault((s) => s.currentVault);

  const stakeRecord = useMemo(
    () =>
      isNil(data) || isNil(currentVault?.pid)
        ? []
        : data.poolStakeMap[currentVault.pid],
    [data, currentVault],
  );

  const handleOnNext = () => {
    setView('select-vault');
  };

  const handleSelectStake = (selected: Stake) => {
    const found = stakes.findIndex((s) => s.stakeId.eq(selected.stakeId));
    setStakes(
      found > -1
        ? stakes.filter((_, index) => index !== found)
        : [...stakes, selected],
    );
  };

  return (
    <>
      <DrawerBody>
        <VStack p={{ base: 0, lg: 4 }}>
          <Text
            textStyle={{ base: 'h1', lg: 'h3' }}
            fontWeight={400}
            fontFamily="luckiestGuy"
          >
            {t('common.WHICH DEPOSITS WOULD YOU LIKE TO UPGRADE?')}
          </Text>
          <DepositSelection
            stakes={stakeRecord}
            selectedIds={stakes.map((s) => s.stakeId)}
            onSelectStake={handleSelectStake}
          />
        </VStack>
      </DrawerBody>
      <DrawerFooter bgColor="#42246D" pt={5}>
        <Button
          w="full"
          visual="primary-light"
          fontFamily="luckiestGuy"
          onClick={handleOnNext}
          disabled={stakes.length === 0}
        >
          {t('common.NEXT & SELECT VAULT')}
        </Button>
      </DrawerFooter>
    </>
  );
};
