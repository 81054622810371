import { defineGlobalStyles } from '@chakra-ui/react';
export const globalCss = defineGlobalStyles({
    body: {
        fontSize: '16px',
        fontFamily: 'Luckiest Guy',
    },
    // no scrollbar
    '.no-scrollbar::-webkit-scrollbar': {
        display: 'none',
    },
    '.no-scrollbar': {
        scrollbarWidth: 'none',
    },
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },
});
