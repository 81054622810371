import { defineConfig } from '@chakra-ui/react';
import { breakpoints } from './breakpoints';
import { colors } from './colors';
import { recipes, slotRecipes } from './components';
import { gradients } from './gradients';
import { semanticTokens } from './semanticTokens';
import { spacing } from './spacing';
import { globalCss } from './styles';
import { textStyles } from './textStyles';
export const chakraConfig = defineConfig({
    globalCss,
    theme: {
        recipes: {
            ...recipes,
        },
        slotRecipes: {
            ...slotRecipes,
        },
        breakpoints,
        textStyles,
        tokens: {
            colors,
            gradients,
            spacing,
        },
        semanticTokens,
    },
});
