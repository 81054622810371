import type { ButtonProps as ChakraCloseButtonProps } from '@chakra-ui/react';
import { IconButton as ChakraIconButton } from '@chakra-ui/react';
import { forwardRef } from 'react';

import { MaskIcon } from './MaskIcon';

export type CloseButtonProps = ChakraCloseButtonProps;

export const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  function CloseButton(props, ref) {
    return (
      <ChakraIconButton
        variant="ghost"
        aria-label="Close"
        ref={ref}
        color="black"
        {...props}
      >
        {props.children ?? (
          <MaskIcon icon="url(/masks/close.svg)" bg="currentColor" />
        )}
      </ChakraIconButton>
    );
  },
);
