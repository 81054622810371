import { Flex, Icon, Text } from '@chakra-ui/react';
import { Vault } from '@cronos-app/icons';
import { BigNumber } from '@ethersproject/bignumber';
import { StakeInfoToken } from '@ui/components/Atoms/StakaInfoToken';
import { VaultInfoChip } from '@ui/components/Atoms/VaultInfoChip';
import { BARA_DECIMALS } from '@ui/config/sc';
import { useCurrentVault } from '@ui/hooks/stores/useCurrentVault';
import { estimateReward, formatApr } from '@ui/utils/vault';
import { Fraction } from 'bi-fraction';
import { isNil } from 'lodash-es';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

export const EstimatedRewardCard = () => {
  const t = useTranslations();

  const { currentVault, inputTokenAmount } = useCurrentVault();
  const rewards = useMemo(
    () =>
      estimateReward(
        Number(inputTokenAmount),
        currentVault?.lockPeriod,
        currentVault?.apr,
      ),
    [inputTokenAmount, currentVault],
  );

  const chips = useMemo(() => {
    const apr = currentVault ? formatApr(currentVault.apr) : undefined;
    const multiplier = t('common.{rate} Multiplier', {
      rate: currentVault?.multiplier ? currentVault.multiplier + 'x' : '--x',
    });

    return [apr, multiplier].filter((item) => !isNil(item));
  }, [t, currentVault]);

  return (
    <Flex
      p={3}
      rounded="8px"
      mt={6}
      w="full"
      flexDir="column"
      background="linear-gradient(102deg, #9226FF 5.03%, #38CFFF 102.71%)"
    >
      <Flex gap={2} alignItems="center">
        <Icon w="32px" h="32px" color="white">
          <Vault />
        </Icon>
        <Flex flexDir="column">
          <Text textStyle="subTitle2" color="white">
            {t("common.Estimated Rewards you'll get")}
          </Text>
          <StakeInfoToken
            value={BigNumber.from(
              new Fraction(rewards ?? 0).shl(BARA_DECIMALS).quotient,
            )}
          />
        </Flex>
      </Flex>
      <Flex mt={5} gap={2}>
        {chips.map((chip, index) => (
          <VaultInfoChip key={index}>{chip}</VaultInfoChip>
        ))}
      </Flex>
    </Flex>
  );
};
