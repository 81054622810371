import { currentWallet, useDesiredChain } from '@cronos-app/wallet';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toaster } from '@ui/components/Atoms/Toast';
import type { Stake } from '@ui/interfaces/stake.interface';
import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import { getSDK } from '@ui/utils';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { getUseBaraBalanceQueryKey } from '../useBaraBalance';
import { getUseBaraVaultUserInfoQueryKey } from './useBaraVaultUserInfo';

interface UpgradeParams {
  stakes: Stake[];
  vault: VaultCardInfo;
}

interface UseBaraVaultUpgradeParams {
  onSuccess?: () => void;
  onFailed?: () => void;
}

export const useBaraVaultUpgrade = () => {
  const t = useTranslations();
  const desiredChainId = useDesiredChain();
  const sdk = getSDK(desiredChainId);

  const [isLoading, setIsLoading] = useState(false);

  const account = currentWallet.useAccount();
  const provider = currentWallet.useProvider();
  const isWrongNetwork = currentWallet.useIsWrongNetwork();

  const queryClient = useQueryClient();

  const data = useMutation(
    ['BaraVault.upgrade'],
    async ({ stakes, vault }: UpgradeParams) => {
      const signer = await provider?.getSigner();
      const baraVaultContract = sdk.getBaraVault(signer);

      setIsLoading(true);
      if (account && !isWrongNetwork) {
        const isSingleStake = stakes.length === 1;
        const stakeIds = stakes.map((stake) => stake.stakeId.toString());
        const vaultIds = stakes.map((_) => vault.pid.toString());

        const ret = isSingleStake
          ? await baraVaultContract.upgrade(stakeIds[0], vaultIds[0])
          : await baraVaultContract.batchUpgrade(stakeIds, vaultIds);

        toaster.create({
          title: t('common.Upgrade_Vault_Loading_Toast_Title', {
            stakes: stakes.length,
          }),
          description: t('common.Upgrade_Vault_Loading_Toast_Message', {
            stakes: stakes.length,
            vault: vault.lockPeriodTitle,
          }),
          type: 'loading',
          id: ret.hash,
          meta: { txHash: ret.hash },
        });

        await ret.wait();

        toaster.dismiss(ret.hash);

        toaster.success({
          title: t('common.Upgrade_Vault_Success_Toast_Title'),
          description: t('common.Upgrade_Vault_Success_Toast_Message', {
            stakes: stakes.length,
            vault: vault.lockPeriodTitle,
          }),
        });
      }
    },
    {
      onError: () => {
        toaster.error({
          title: t('common.Upgrade_Vault_Error_Toast_Title'),
          description: t('common.Upgrade_Vault_Error_Toast_Message'),
        });
        setIsLoading(false);
      },
      onSuccess: () => {
        setIsLoading(false);
        queryClient.invalidateQueries(getUseBaraVaultUserInfoQueryKey(account));
        queryClient.invalidateQueries(getUseBaraBalanceQueryKey(account));
      },
    },
  );

  return {
    ...data,
    isLoading,
  };
};
