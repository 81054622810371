import type { BigNumber } from '@ethersproject/bignumber';
import { USD_DECIMALS } from '@ui/config/sc';

import { expandDecimals, parseStringValueToBn } from './formatNumber';
import { expandExpNumber } from './helpers';

export const convertUsdPriceNumberToUsdPriceBn = (price: number): BigNumber =>
  parseStringValueToBn(expandExpNumber(price), USD_DECIMALS);

export const convertTokenAmountBnToUsdValueBn = (
  tokenAmountBn: BigNumber,
  usdPriceBn: BigNumber,
) => tokenAmountBn.mul(usdPriceBn).div(expandDecimals(USD_DECIMALS));

export const convertTokenAmountBnToUsdPriceBn = (
  tokenBn: BigNumber,
  tokenUsdPrice: number,
) => {
  return convertTokenAmountBnToUsdValueBn(
    tokenBn,
    convertUsdPriceNumberToUsdPriceBn(tokenUsdPrice),
  );
};
