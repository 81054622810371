import {
  DrawerBody,
  DrawerFooter,
  Flex,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import { Amount } from '@ui/components/Atoms/Amount';
import { Checkbox } from '@ui/components/Atoms/Checkbox';
import { RowHighlightSelect } from '@ui/components/Atoms/RowHighlightSelect';
import UnlockDate from '@ui/components/Atoms/UnlockDate';
import WhiteButton from '@ui/components/Atoms/WhiteButton';
import useBaraVaultBatchWithdraw from '@ui/hooks/contracts/BaraVault/useBaraVaultBatchWithdraw';
import useBaraVaultUnlockStakes from '@ui/hooks/contracts/BaraVault/useBaraVaultUnlockStakes';
import useBaraVaultUserInfo from '@ui/hooks/contracts/BaraVault/useBaraVaultUserInfo';
import { useCurrentVault } from '@ui/hooks/stores/useCurrentVault';
import { useStakeModal } from '@ui/hooks/stores/useStakeModalView';
import type { Stake } from '@ui/interfaces/stake.interface';
import { captureVaultMultipleWithdraw } from '@ui/utils/posthog/captureVaultActions';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo, useState } from 'react';

import BaraAmountUsd from '../BaraAmountUsd';

const MultipleWithdrawModalView = () => {
  const closeModal = useStakeModal((s) => s.closeModal);
  const currentVault = useCurrentVault((s) => s.currentVault);
  const [selectedStakeIds, setSelectedStakeIds] = useState<string[]>([]);

  const { isLoading, mutateAsync } = useBaraVaultBatchWithdraw();
  const { data } = useBaraVaultUserInfo();

  const currentAvailableWithdrawStakes = useBaraVaultUnlockStakes(currentVault);

  const t = useTranslations();

  const handleConfirm = async () => {
    await mutateAsync(selectedStakeIds, {
      onSuccess: () => {
        if (!currentVault) return;
        captureVaultMultipleWithdraw(
          selectedStakeIds,
          currentVault,
          totalAmount,
        );
      },
    });
    closeModal();
  };

  const isAllSelected = useMemo(() => {
    return currentAvailableWithdrawStakes.length === selectedStakeIds.length;
  }, [currentAvailableWithdrawStakes.length, selectedStakeIds.length]);

  const handleSelect = (stake: Stake) => () => {
    if (selectedStakeIds.includes(stake.stakeId.toString())) {
      setSelectedStakeIds((prev) =>
        prev.filter((id) => id !== stake.stakeId.toString()),
      );
    } else {
      setSelectedStakeIds((prev) => [...prev, stake.stakeId.toString()]);
    }
  };

  const handleSelectedAll = useCallback(() => {
    if (isAllSelected) {
      setSelectedStakeIds([]);
    } else {
      setSelectedStakeIds(
        currentAvailableWithdrawStakes.map((stake) => stake.stakeId.toString()),
      );
    }
  }, [currentAvailableWithdrawStakes, isAllSelected]);

  const totalAmount = useMemo(() => {
    if (!currentVault || !data?.poolStakeMap) return BigNumber.from(0);

    return selectedStakeIds.reduce((acc, id) => {
      const stake = data.poolStakeMap[currentVault.pid].find(
        (s) => s.stakeId.toString() === id,
      );

      if (!stake) return acc;

      return acc.add(stake.amount);
    }, BigNumber.from(0));
  }, [currentVault, data?.poolStakeMap, selectedStakeIds]);

  return (
    <>
      <DrawerBody>
        <VStack p={4} h="full">
          <Text
            textStyle={{ base: 'h1', lg: 'h3' }}
            fontWeight={400}
            fontFamily="luckiestGuy"
            color="cPurple.500"
          >
            {t('common.WHICH DEPOSIT WOULD YOU LIKE TO WITHDRAW')}
          </Text>
          <HStack w="full" pr={3}>
            <Text
              ml="auto"
              textStyle="caption3"
              color="cPurple.500"
              fontWeight={700}
            >
              {t('common.Select All')}
            </Text>
            <Checkbox
              size="lg"
              checked={isAllSelected}
              onChange={handleSelectedAll}
            />
          </HStack>
          <VStack gap={5} w="full">
            {currentAvailableWithdrawStakes.map((stake) => (
              <RowHighlightSelect
                key={stake.stakeId.toString()}
                isSelected={selectedStakeIds.includes(stake.stakeId.toString())}
                onSelect={handleSelect(stake)}
              >
                <Flex flexDir="column" pt={2} color="#42246D">
                  <Amount
                    value={stake.amount}
                    decimals={18}
                    textStyle="h5"
                    fontWeight={700}
                    fontSize={20}
                    suffix=" BARA"
                  />
                  <UnlockDate
                    isInStakeModal
                    unlockTimestamp={stake.unlockTimestamp}
                  />
                </Flex>
              </RowHighlightSelect>
            ))}
          </VStack>
          <Text textStyle="caption4" color="cPurple.500" mt={6} w="full">
            {t('common.Withdraw_Disclosure_1')}
            <br />
            {t('common.Withdraw_Disclosure_2')}
          </Text>
        </VStack>
      </DrawerBody>
      <DrawerFooter background="cPurple.500" p={4}>
        <VStack w="full" alignItems="flex-start">
          {!totalAmount.isZero() && (
            <>
              <Text color="white" fontSize="16px" fontFamily="luckiestGuy">
                {t('common.Total Amount')}
              </Text>
              <Flex alignContent="space-between" w="full">
                <Amount
                  color="white"
                  value={totalAmount}
                  decimals={18}
                  textStyle="h5"
                  fontSize={20}
                  suffix=" BARA"
                />
                <BaraAmountUsd ml="auto" value={totalAmount} fontSize="16px" />
              </Flex>
            </>
          )}
          <WhiteButton
            w="full"
            loading={isLoading}
            onClick={handleConfirm}
            disabled={selectedStakeIds.length === 0}
          >
            {t('common.CONFIRM WITHDRAWAL')}
          </WhiteButton>
        </VStack>
      </DrawerFooter>
    </>
  );
};

export default MultipleWithdrawModalView;
