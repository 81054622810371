import { Global } from '@emotion/react';
import { Luckiest_Guy, Passion_One, Roboto } from 'next/font/google';

import { fontFamilyVariable } from './fontChakraConfig';

const LuckiestGuy = Luckiest_Guy({
  subsets: ['latin'],
  display: 'swap',
  weight: '400',
  preload: true,
});

const RobotoFont = Roboto({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '700', '900'],
  preload: true,
});

const PassionOneFont = Passion_One({
  subsets: ['latin'],
  display: 'swap',
  weight: ['400', '700', '900'],
  preload: true,
});

export const Fonts = () => {
  return (
    <Global
      styles={`
      :root {
        ${fontFamilyVariable.default}: ${PassionOneFont.style.fontFamily};
        ${fontFamilyVariable.heading}: ${PassionOneFont.style.fontFamily};
        ${fontFamilyVariable.slogan}: ${PassionOneFont.style.fontFamily};
        ${fontFamilyVariable.footer}: ${RobotoFont.style.fontFamily};
        ${fontFamilyVariable.luckiestGuy}: ${LuckiestGuy.style.fontFamily};
      }
    `}
    />
  );
};
