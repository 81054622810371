import type { SkeletonProps } from '@chakra-ui/react';
import { Box, Skeleton } from '@chakra-ui/react';

export type VaultInfoChipProps = SkeletonProps;

export const VaultInfoChip = ({
  children,
  ...skeletonProps
}: VaultInfoChipProps) => {
  return (
    <Skeleton
      textStyle="subTitle2"
      color="#C377FF"
      loading={!children}
      minW={20}
      minH={6}
      {...skeletonProps}
    >
      <Box
        rounded="100px"
        bg="cPurple.300"
        px={3}
        pt={0.5}
        pb={1}
        boxSize="fit-content"
      >
        {children}
      </Box>
    </Skeleton>
  );
};
