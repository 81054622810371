/* eslint-disable @ts-intl/ts-intl/no-raw-text */
export const NavItemUrls = [
  {
    title: 'Home',
    url: '/',
  },
];

if (process.env.IS_DISABLED_STAKING !== 'true') {
  NavItemUrls.push({
    title: 'Staking',
    url: '/stake',
  });
}

export const VVS_SWAP_BASE = 'https://vvs.finance/swap';

export enum VvsToken {
  ETH = 'ETH',
  CRO = 'CRO',
  CORGIAI = 'CORGIAI',
  DOGE = 'DOGE',
  SHIBA = 'SHIBA',
  WCRO = 'WCRO',
  USDC = 'USDC',
  BARA = 'BARA',
}

export const WHITE_PAPER_URL =
  'https://capybara-nation.gitbook.io/capybara-nation';

export const TERMS_AND_CONDITIONS_URL = '/about/terms-and-conditions.pdf';

export const VvsTokenApiUrl = 'https://api.vvs.finance/info/api/tokens';

export const VvsTokenAddressMap: Record<VvsToken, string> = {
  [VvsToken.ETH]: '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
  [VvsToken.CRO]: '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae2359',
  [VvsToken.CORGIAI]: '0x6b431B8a964BFcf28191b07c91189fF4403957D0',
  [VvsToken.DOGE]: '0x1a8E39ae59e5556B56b76fCBA98d22c9ae557396',
  [VvsToken.SHIBA]: '0xbED48612BC69fA1CaB67052b42a95FB30C1bcFee',
  [VvsToken.WCRO]: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
  [VvsToken.USDC]: '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
  [VvsToken.BARA]: '0xF24409D155965ca87c45aD5BC084aD8aD3Be4f39',
};

export const VvsSwapUrl = (from?: VvsToken, to?: VvsToken) => {
  const url = new URL(VVS_SWAP_BASE);

  if (from) url.searchParams.append('inputCurrency', VvsTokenAddressMap[from]);
  if (to) url.searchParams.append('outputCurrency', VvsTokenAddressMap[to]);

  return url.toString();
};

export enum InfoApiTokenSlug {
  Bara = 'bara',
}
export const InfoApiTokenPriceUrl = (slug: InfoApiTokenSlug) =>
  `https://info.capybaranation.xyz/api/token/${slug}/price`;
