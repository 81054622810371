import type { SkeletonProps } from '@chakra-ui/react';
import { Box, Skeleton } from '@chakra-ui/react';

export const VaultInfoTag = ({ children, ...skeletonProps }: SkeletonProps) => {
  return (
    <Skeleton h="fit-content" minH={6} minW={20} {...skeletonProps}>
      <Box rounded="100px" bg="#4700A6" px={2.5} pt={1} boxSize="fit-content">
        {children}
      </Box>
    </Skeleton>
  );
};
