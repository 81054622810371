import {
  Button,
  DrawerBody,
  DrawerFooter,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Arrow } from '@cronos-app/icons';
import { useUpgradeVault } from '@ui/contexts/UpgradeVaultContext';
import useVaultInfoList from '@ui/hooks/contracts/BaraVault/useVaultInfoList';
import { useCurrentVault } from '@ui/hooks/stores/useCurrentVault';
import { useUpgradeModal } from '@ui/hooks/stores/useUpgradeModalView';
import type { VaultCardInfo } from '@ui/interfaces/vault.interface';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import { VaultSelection } from './VaultSelection';

export const SelectUpgradeVaultModalView = () => {
  const t = useTranslations();
  const [selectedVault, setSelectedVault] = useState<VaultCardInfo>();

  const { setView } = useUpgradeModal();
  const { stakes, setVault } = useUpgradeVault();

  const currentVault = useCurrentVault((s) => s.currentVault);
  const { data: vaultInfoList } = useVaultInfoList();

  const handleOnNext = () => {
    setVault(selectedVault);
    setView('confirm-upgrade');
  };

  return (
    <>
      <DrawerBody>
        <VStack w="full" align="start" gapY={0}>
          <Text
            textStyle={{ base: 'h1', lg: 'h3' }}
            fontWeight={400}
            fontFamily="luckiestGuy"
          >
            {t('common.WHICH VAULT WOULD YOU LIKE TO UPGRADE TO?')}
          </Text>

          <VStack w="full" align="start" spaceY={3}>
            <Text textStyle="h5" mt={{ base: 3, lg: 6 }}>
              {t('common.FROM')}
            </Text>
            {currentVault && (
              <VaultSelection vault={currentVault} stakes={stakes} />
            )}
          </VStack>

          <Icon
            style={{ rotate: '90deg' }}
            mx="auto"
            mt={{ base: 1, lg: 2 }}
            boxSize={{ base: 5, lg: 6 }}
          >
            <Arrow />
          </Icon>

          <VStack w="full" align="start" gapY={3}>
            <Text textStyle="h5" mt={{ base: 1, lg: 4 }}>
              {t('common.TO')}
            </Text>
            {!!currentVault &&
              !!vaultInfoList &&
              vaultInfoList
                .filter((vault) => vault.pid > currentVault.pid)
                .map((vault) => (
                  <VaultSelection
                    key={vault.pid}
                    vault={vault}
                    stakes={stakes}
                    variant="radio"
                    isSelected={selectedVault?.pid === vault.pid}
                    onSelect={() => {
                      setSelectedVault(vault);
                    }}
                  />
                ))}
          </VStack>
        </VStack>
      </DrawerBody>
      <DrawerFooter bgColor="#42246D" pt={5}>
        <Button
          w="full"
          visual="primary-light"
          fontFamily="luckiestGuy"
          onClick={handleOnNext}
          disabled={!selectedVault}
        >
          {t('common.NEXT & REVIEW')}
        </Button>
      </DrawerFooter>
    </>
  );
};
