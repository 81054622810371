import { Button, Skeleton, VStack } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';
import { DrawerBody, DrawerFooter } from '@ui/components/Organisms/Drawer';
import useBaraVaultUnlockStakes from '@ui/hooks/contracts/BaraVault/useBaraVaultUnlockStakes';
import useBaraVaultUserInfo from '@ui/hooks/contracts/BaraVault/useBaraVaultUserInfo';
import useBaraWithdraw from '@ui/hooks/contracts/BaraVault/useBaraWithdraw';
import { useCurrentVault } from '@ui/hooks/stores/useCurrentVault';
import { useStakeModal } from '@ui/hooks/stores/useStakeModalView';
import { useUpgradeModal } from '@ui/hooks/stores/useUpgradeModalView';
import type { Stake } from '@ui/interfaces/stake.interface';
import { captureVaultSingleWithdraw } from '@ui/utils/posthog/captureVaultActions';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import CurrentStakeInfoDetail from '../CurrentStakeInfoDetail';
import StakeInfoRow from '../StakeInfoRow';

const CurrentVaultModalView = () => {
  const currentVault = useCurrentVault((s) => s.currentVault);
  const { isLoading, data } = useBaraVaultUserInfo();
  const { isLoading: isWithdrawLoading, mutateAsync } = useBaraWithdraw();

  const setView = useStakeModal((s) => s.setView);
  const closeStakeModal = useStakeModal((s) => s.closeModal);
  const openUpgradeModal = useUpgradeModal((s) => s.openModal);

  const t = useTranslations();
  const currentAvailableWithdrawStakes = useBaraVaultUnlockStakes(currentVault);

  const handleOnNext = () => {
    setView('deposit');
  };

  const handleOnUpgrade = () => {
    closeStakeModal();
    openUpgradeModal('select-deposit');
  };

  const stakes = useMemo<Stake[]>(() => {
    if (!currentVault || !data?.poolStakeMap) return [];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return data.poolStakeMap[currentVault.pid] ?? [];
  }, [currentVault, data]);

  const handleWithdraw = (stake: Stake) => () => {
    if (currentAvailableWithdrawStakes.length > 1) {
      setView('withdraw');
    } else {
      mutateAsync(stake.stakeId, {
        onSuccess: () => {
          if (!currentVault) return;
          captureVaultSingleWithdraw(stake, currentVault);
        },
      });
    }
  };

  return (
    <>
      <DrawerBody>
        <VStack>
          {currentVault && (
            <CurrentStakeInfoDetail
              currentVault={currentVault}
              totalStaked={
                data?.poolTotalStakeMap[currentVault.pid] ?? BigNumber.from(0)
              }
            />
          )}
          <VStack width="50%" ml="auto" gap={6} mt={6} align="start">
            {isLoading && (
              <>
                <Skeleton height="30px" mt={4} />
                <Skeleton height="30px" mt={2} />
                <Skeleton height="30px" mt={2} width="80%" />
              </>
            )}

            {stakes.map((stake) => (
              <StakeInfoRow
                stake={stake}
                key={stake.stakeTimestamp.toString()}
                onWithdraw={handleWithdraw(stake)}
                isWithdrawLoading={isWithdrawLoading}
              />
            ))}
          </VStack>
        </VStack>
      </DrawerBody>
      <DrawerFooter justifyContent="space-evenly">
        <Button visual="primary-outline" flexGrow={1} onClick={handleOnNext}>
          {t('common.DEPOSIT MORE')}
        </Button>
        {process.env.IS_DISABLED_UPGRADE_VAULT !== 'true' &&
          !!currentVault &&
          currentVault.pid !== 2 && (
            <Button
              visual="primary-light"
              flexGrow={1}
              onClick={handleOnUpgrade}
            >
              {t('common.UPGRADE VAULT')}
            </Button>
          )}
      </DrawerFooter>
    </>
  );
};

export default CurrentVaultModalView;
