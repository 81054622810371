import { ChainId } from '@cronos-app/sdk';
const SUPPORTED_CHAIN_IDS = [Number(process.env.CHAIN_ID)];
export const getIsSupportedChainId = (chainId) => !!chainId && SUPPORTED_CHAIN_IDS.includes(chainId);
export const explorers = {
    [ChainId.CRONOS_MAINNET]: 'https://cronoscan.com',
    [ChainId.CRONOS_TESTNET]: 'https://testnet.cronoscan.com',
};
const CHAIN_CONFIG = {
    [ChainId.CRONOS_MAINNET]: {
        chainId: ChainId.CRONOS_MAINNET,
        chainName: 'Cronos',
        nativeCurrency: {
            name: 'CRO',
            symbol: 'cro',
            decimals: 18,
        },
        rpcUrls: [process.env.RPC_URL_25],
        blockExplorerUrls: [explorers[ChainId.CRONOS_MAINNET]],
    },
    [ChainId.CRONOS_TESTNET]: {
        chainId: 338,
        chainName: 'Cronos Testnet',
        nativeCurrency: {
            name: 'CRO',
            symbol: 'cro',
            decimals: 18,
        },
        rpcUrls: [process.env.RPC_URL_338],
        blockExplorerUrls: [explorers[ChainId.CRONOS_TESTNET]],
    },
};
export const getAddEthereumChainParameter = (chainId) => {
    return CHAIN_CONFIG[chainId];
};
