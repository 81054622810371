import { useStakeModal } from '@ui/hooks/stores/useStakeModalView';
import { useUpgradeModal } from '@ui/hooks/stores/useUpgradeModalView';

import { AccountModal } from './Atoms/AccountModal';
import { useAccountModal } from './Atoms/AccountModal/useAccountModal';
import { Toaster } from './Atoms/Toast';
import { useWalletModal, WalletModal } from './Atoms/Wallet';
import { DevUtils } from './DevUtils';
import { LazyComponent } from './LazyComponent';
import StakeModal from './Organisms/StakeModal';
import UpgradeModal from './Organisms/UpgradeModal';

export const GlobalComponents = () => {
  const isOpenWalletModalOpen = useWalletModal((s) => s.isOpen);
  const isAccountModalOpen = useAccountModal((s) => s.isOpen);
  const isStakeModalOpen = useStakeModal((s) => s.isOpen);
  const isUpgradeModalOpen = useUpgradeModal((s) => s.isOpen);

  return (
    <>
      <LazyComponent isOpen={isOpenWalletModalOpen}>
        <WalletModal />
      </LazyComponent>
      <LazyComponent isOpen={isAccountModalOpen}>
        <AccountModal />
      </LazyComponent>
      <LazyComponent isOpen={isStakeModalOpen}>
        <StakeModal />
      </LazyComponent>
      <LazyComponent isOpen={isUpgradeModalOpen}>
        <UpgradeModal />
      </LazyComponent>
      <DevUtils />
      <Toaster />
    </>
  );
};
